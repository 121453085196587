import {
  Loader,
  Table,
  useColumnsState,
  useTableBESort,
  useTableSearch,
} from 'components';
import React, { useState, useMemo } from 'react';
import useAggregateTableRequest from '../hooks/useAggregateTableRequest';
import { RumTableColumnKey } from '../contants';
import { rumViewTableColumns } from '../rumEventTableColumns';
import useRumState from '../hooks/useRumState';
import { TableSearch } from 'components';
import { Select } from 'components/SelectV2';

type Metric =
  | 'view.largest_contentful_paint'
  | 'view.cumulative_layout_shift'
  | 'view.interaction_to_next_paint';
type Value = string | number;
type Props = {
  rumState: ReturnType<typeof useRumState>;
};

const getColorForMetric = (metric: Metric, value: Value): string => {
  const numericValue = parseFloat(value.toString());

  switch (metric) {
    case 'view.largest_contentful_paint':
      if (numericValue > 4) return '#8B0000';
      if (numericValue >= 2.5 && numericValue <= 4) return '#FFA500';
      return '#006400';

    case 'view.cumulative_layout_shift':
      if (numericValue > 0.25) return '#8B0000';
      if (numericValue >= 0.1 && numericValue <= 0.25) return '#FFA500';
      return '#006400';

    case 'view.interaction_to_next_paint':
      if (numericValue > 0.5) return '#8B0000';
      if (numericValue >= 0.2 && numericValue <= 0.5) return '#FFA500';
      return '#006400';
  }
};

const RumPerformanceOverviewTopPages = ({ rumState }: Props) => {
  const { dateState, rumTableSortState, selectedFacetValuesByNameState } =
    rumState;
  const [date] = dateState;
  const aggregationMapping: { [key: string]: string } = {
    P99: 'percentile99',
    P95: 'percentile95',
    P90: 'percentile90',
    P75: 'percentile75',
    P50: 'percentile50',
  };
  const [aggregationFunction, setAggregationFunction] = useState(
    aggregationMapping['P99'],
  );
  const rumAggregatesRequest = useAggregateTableRequest({
    date,
    rumTableSortState,
    selectedFacetValuesByNameState,
    aggregationFunction,
  });
  const regularColumns = rumViewTableColumns();
  const regularColumnsState = useColumnsState({
    columns: regularColumns,
    initialState: {
      resizedWidths: {},
      selectedColumns: {
        [RumTableColumnKey.urlPath]: 1,
        [RumTableColumnKey.loadingTime]: 1,
        [RumTableColumnKey.largestContentfulPaint]: 1,
        [RumTableColumnKey.cumulativeLayoutShift]: 1,
        [RumTableColumnKey.interactionToNextPaint]: 1,
      },
    },
    key: 'rum-aggregate-table',
  });
  const rows = useMemo(
    () => rumAggregatesRequest.result || [],
    [rumAggregatesRequest.result],
  );
  const tableSearch = useTableSearch({ rows });
  const tableSort = useTableBESort({
    columns: regularColumns,
    initialKey: RumTableColumnKey.date,
    rows: tableSearch.searchedRows,
    onSortChange: ({ sortBy, sortOrder }) => {
      rumTableSortState?.sortBy({ sortBy, sortOrder });
    },
  });

  const renderColoredMetric = (metric: Metric, value: Value) => (
    <span style={{ color: getColorForMetric(metric, value) }}>{value}</span>
  );

  const enhancedRows = useMemo(() => {
    return rows.map((row) => {
      const { data } = row;

      return {
        ...row,
        data: {
          ...data,
          'view.largest_contentful_paint': renderColoredMetric(
            'view.largest_contentful_paint',
            data['view.largest_contentful_paint'],
          ),
          'view.cumulative_layout_shift': renderColoredMetric(
            'view.cumulative_layout_shift',
            data['view.cumulative_layout_shift'],
          ),
          'view.interaction_to_next_paint': renderColoredMetric(
            'view.interaction_to_next_paint',
            data['view.interaction_to_next_paint'],
          ),
        },
      };
    });
  }, [rows]);

  return (
    <>
      <Loader
        className="rum-events__table overflow-auto"
        isLoading={rumAggregatesRequest.isLoading}
        dataTestId="rum-events-table"
      >
        <div className="mr-9">
          {tableSearch ? (
            <TableSearch
              placeholder="Search"
              tableSearch={tableSearch}
              dataTestId="table-search"
            />
          ) : null}
        </div>

        <div className="mr-9 flex">
          <Select
            className="rum-performance-percentile-dropdown"
            value={Object.keys(aggregationMapping).find(
              (key) => aggregationMapping[key] === aggregationFunction,
            )}
            onChange={(value) =>
              setAggregationFunction(aggregationMapping[value])
            }
            options={Object.keys(aggregationMapping).map((key) => ({
              label: key,
              value: key,
            }))}
          />
        </div>

        <Table
          className="table--padded table--bordered-cells font-robotoMono"
          columns={regularColumnsState.renderedColumns}
          externalTableSort={tableSort}
          isResizingEnabled
          isSortingEnabled
          rows={enhancedRows}
          tableKey="rum-events-table"
        />
      </Loader>
    </>
  );
};

export default RumPerformanceOverviewTopPages;

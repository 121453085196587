import React from 'react';
import LogsTabs from './LogsTabs';
import { MdBarChart } from 'react-icons/md';
import { useLocalStorageToggle } from 'hooks';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  isAnalyticsView: boolean;
  showTimelineToggle: ReturnType<typeof useLocalStorageToggle>;
  error: {
    getFacetNames: { message: string };
  };
};

const LogsTabsContainer = ({
  isAnalyticsView,
  showTimelineToggle,
  error,
}: Props) => {
  return (
    <div className="logs__search__bottom">
      <LogsTabs />
      <div className="logs__search__bottom__right">
        {!showTimelineToggle.value && !isAnalyticsView ? (
          <div className="logs__search__bottom__right__item">
            <button
              className="button button--short"
              onClick={showTimelineToggle.on}
            >
              <div className="button__icon">
                <MdBarChart size={16} />
              </div>
              <div className="button__text">Show Timeline</div>
            </button>
          </div>
        ) : null}
      </div>

      {error?.getFacetNames && (
        <div className="flex gap-[4px] w-full justify-end pr-[14px]">
          <IoIosWarning
            className="overlay-message__icon-and-message__icon"
            size={16}
          />
          <div className="text-red-500">{error?.getFacetNames?.message}</div>
        </div>
      )}
    </div>
  );
};

export default LogsTabsContainer;

import delimiter from 'kfuse-constants/delimiter';
import {
  LogsMetricQueryProps,
  LogQLBuilderFilterProps,
  RangeAggregate,
  SelectedFacetValuesByName,
} from 'types';
import {
  checkLogsMetricType,
  findFirstOperator,
  getIsInQuotes,
  getWithoutQuotes,
  getUrlParamByKey,
  getOperatorSign,
  getLogsOperatorSign,
  isSourceLabel,
  logsQueryOperator,
  parseFacetKey,
  getIsComponentLabelStrict,
  getIsLogRangeFacet,
} from 'utils';
import { validateLogqlQuery } from './logqlValidator';
import { getLegacyLogsStateFromFiltersState } from 'hooks';

const getOperator = (op: 'eq' | 'neq', values: string[]) => {
  if (values.length > 1) {
    return op === 'eq' ? '=~' : '!~';
  }

  return op === 'eq' ? '=' : '!=';
};

export const FINGERPRINT_KEY = `fingerprint${delimiter}fingerprint${delimiter}string`;

export const buildLogql = ({
  customerFilter,
  queries,
  step = '1m',
}: {
  customerFilter?: { key: string; value: string };
  queries: LogsMetricQueryProps[];
  step?: string;
}): string[] => {
  const enableLokiPushdown = getUrlParamByKey('enableLokiPushdown');
  const logql: string[] = [];
  queries.forEach((query: LogsMetricQueryProps) => {
    let innerLogql = '';
    const {
      filtersState,
      metric,
      rangeAggregate,
      rangeAggregateGrouping,
      rangeAggregateParam,
      limit,
    } = query;
    if (!validateLogqlQuery(query)) {
      logql.push('');
      return;
    }

    const filters = filtersState
      ? getLegacyLogsStateFromFiltersState({ filtersState })
      : {
          filterOrExcludeByFingerprint: {},
          keyExists: query.filters.keyExists || {},
          filterByFacets: query.filters.filterByFacets || [],
          searchTerms: query.filters.searchTerms || [],
          selectedFacetRangeByName: {},
          selectedFacetValuesByName: query.filters.sidebarFilters || {},
        };

    // debugger;
    const { grepFilter, labelFilter, pipelineFilter } = buildLogqlFilter({
      customerFilter,
      filters,
    });

    const pipelineFilterQuery = buildPipelineFilter({
      pipelineFilter,
      metric,
      rangeAggregateGrouping,
      rangeAggregate,
    });

    const rangeAggregateParamValue =
      rangeAggregate === RangeAggregate.quantile_over_time
        ? `${rangeAggregateParam}, ` || '0, '
        : '';

    innerLogql = `${rangeAggregate}(${rangeAggregateParamValue}{ ${labelFilter} }  ${grepFilter} ${pipelineFilterQuery} [${step}])`;
    if (metric === '*') {
      innerLogql = `sum(${innerLogql})`;
    }

    const groupByRange = buildLogqlGroupBy(rangeAggregateGrouping, metric);
    const parsedMetric = checkLogsMetricType(metric);
    const [name, type] = metric.split(delimiter);
    const isNumeric = getIsLogRangeFacet(type || '');
    if (
      (parsedMetric === 'facet' && !isNumeric) ||
      parsedMetric === 'fp' ||
      parsedMetric === 'label'
    ) {
      innerLogql = `count(count(${innerLogql}) ${groupByRange.inner})`;
    }

    if (groupByRange.outer) {
      innerLogql += `${groupByRange.outer}`;
    }

    if (enableLokiPushdown) {
      innerLogql = `${limit.direction}(${limit.count}, ${innerLogql})`;
    }

    logql.push(innerLogql);
  });

  return logql;
};

const buildLogqlGroupBy = (groupBy: string[], metric: string) => {
  if (groupBy.length === 0 && metric === '*') {
    return { inner: '', outer: '' };
  }

  const outerGroupByFacet: string[] = [];
  const innerGroupByFacet: string[] = [];

  groupBy.map((facet: string) => {
    const [source, facetName] = facet.split(delimiter);
    if (facet.startsWith('@')) {
      const sanitizedName = source.slice(1);
      outerGroupByFacet.push(sanitizedName);
      return;
    } else {
      outerGroupByFacet.push(facetName);
    }
  });

  const parsedMetric = checkLogsMetricType(metric);
  if (parsedMetric === 'fp' || parsedMetric === 'label') {
    const [source, facet] = metric.split(delimiter);
    innerGroupByFacet.push(facet);
    innerGroupByFacet.push(...outerGroupByFacet);
  }

  const [name, type] = metric.split(delimiter);
  const isNumeric = getIsLogRangeFacet(type || '');
  if (parsedMetric === 'facet' && !isNumeric) {
    const sanitizedName = name.startsWith('@') ? name.slice(1) : name;
    innerGroupByFacet.push(sanitizedName);
    innerGroupByFacet.push(...outerGroupByFacet);
  }

  if (outerGroupByFacet.length === 0) {
    outerGroupByFacet.push('__everything__');
  }

  const innerGroupByFacetStr =
    innerGroupByFacet.length > 0 ? ` by (${innerGroupByFacet.join(', ')})` : '';

  const outerGroupByFacetStr = ` by (${outerGroupByFacet.join(', ')})`;
  return {
    inner: innerGroupByFacetStr,
    outer: outerGroupByFacetStr,
  };
};

/**
 * `buildPipelineFilter` is a function that constructs a pipeline filter string for log data processing.
 *
 * @param {Object} params - The parameters for the function.
 * @param {string} params.pipelineFilter - The pipeline filter string to be applied.
 * @param {string} params.metric - The metric to be used in the filter.
 * @param {string[]} params.rangeAggregateGrouping - An array of strings representing the range aggregate grouping.
 * @param {RangeAggregate} params.rangeAggregate - The range aggregate to be used in the filter.
 *
 * @returns {string} - The constructed pipeline filter string.
 *
 */
const buildPipelineFilter = ({
  pipelineFilter,
  metric,
  rangeAggregateGrouping,
  rangeAggregate,
}: {
  pipelineFilter: string;
  metric: string;
  rangeAggregateGrouping: string[];
  rangeAggregate: RangeAggregate;
}): string => {
  const pipelines = [];

  // If there is a range aggregate grouping or filter then add logfmt to the pipeline
  if (pipelineFilter || rangeAggregateGrouping.length > 0) {
    pipelines.push('logfmt');
  }

  if (pipelineFilter) {
    pipelines.push(pipelineFilter);
  }

  const parsedMetric = checkLogsMetricType(metric);
  if (parsedMetric === 'facet') {
    const [name, type] = metric.split(delimiter);
    const typeLowered = type?.toLowerCase();
    const isNumeric = getIsLogRangeFacet(typeLowered);
    if (isNumeric) {
      const nameSanitized = name.startsWith('@') ? name.slice(1) : name;
      let unwrapFacet = '';
      if (typeLowered === 'number') {
        unwrapFacet = nameSanitized;
      } else {
        unwrapFacet = `${typeLowered}( ${nameSanitized} )`;
      }

      if (pipelines.length === 0) {
        pipelines.push('logfmt');
      }
      pipelines.push(`unwrap ${unwrapFacet}`);
    }
  }

  if (
    rangeAggregate === RangeAggregate.stddev_over_time ||
    rangeAggregate === RangeAggregate.stdvar_over_time ||
    rangeAggregate === RangeAggregate.quantile_over_time
  ) {
    const errorClause = '__error__=""';
    pipelines.push(errorClause);
  }

  if (pipelines.length === 0) {
    return '';
  }
  return `| ${pipelines.join(' | ')}`;
};

/**
 * Build a logql filter from the search terms, filter by facets, and sidebar filters
 * @param searchTerms
 * @param filterByFacets
 * @param sidebarFilters
 */
const buildLogqlFilter = ({
  customerFilter,
  filters,
}: {
  customerFilter?: { key: string; value: string };
  filters: LogQLBuilderFilterProps;
}): {
  labelFilter: string;
  pipelineFilter: string;
  grepFilter: string;
} => {
  const {
    filterByFacets,
    keyExists,
    searchTerms,
    selectedFacetRangeByName,
    selectedFacetValuesByName,
  } = filters;
  const labelFilter: string[] = [];
  const pipelineFilter: string[] = [];
  let grepFilter = '';

  if (Object.keys(selectedFacetValuesByName).length > 0) {
    const sidebarFilterGroup = buildLogqlFilterBySidebarFilters(
      selectedFacetValuesByName,
    );
    pipelineFilter.push(...sidebarFilterGroup.facet);
    labelFilter.push(...sidebarFilterGroup.label);
  }

  if (Object.keys(keyExists).length > 0) {
    const keyExistsFilterGroup = buildLogqlFilterByKeyExists(keyExists);
    pipelineFilter.push(...keyExistsFilterGroup.facet);
    labelFilter.push(...keyExistsFilterGroup.label);
  }

  if (filterByFacets.length > 0) {
    const facetFilterGroup = buildLogqlFilterByFacets(filterByFacets);
    pipelineFilter.push(...facetFilterGroup.facet);
    labelFilter.push(...facetFilterGroup.label);
  }

  if (searchTerms.length > 0) {
    const searchTermsFilterGroup = buildLogqlFilterBySearchTerms(searchTerms);
    grepFilter = searchTermsFilterGroup.grep.join(' ');
  }

  if (Object.keys(selectedFacetRangeByName).length > 0) {
    const facetRangeFilterGroup = buildLogqlFilterByFacetRange(
      selectedFacetRangeByName,
    );
    pipelineFilter.push(...facetRangeFilterGroup.facet);
    labelFilter.push(...facetRangeFilterGroup.label);
  }

  if (
    customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
  ) {
    labelFilter.push(`${customerFilter.key}="${customerFilter.value}"`);
  }

  const labelFilterStr = addKeyExistsSourceToLabelFilter(labelFilter);

  return {
    labelFilter: labelFilterStr,
    pipelineFilter: pipelineFilter.join(' and '),
    grepFilter,
  };
};

/**
 * Build a logql filter from the search terms
 * @param searchTerms
 * @returns
 * @example searchTerms = ['=:foo', '!=:bar']
 */
const buildLogqlFilterBySearchTerms = (
  searchTerms: string[],
): {
  grep: string[];
} => {
  const filter: { grep: string[] } = { grep: [] };
  searchTerms.forEach((rawSearchTerm: string) => {
    const isNeq = rawSearchTerm[0] === '!';
    const searchTerm = isNeq ? rawSearchTerm.slice(1) : rawSearchTerm;
    const isInQuotes = getIsInQuotes(searchTerm);
    const op = isNeq ? '!=' : '=';

    if (!isInQuotes) {
      filter.grep.push(
        `| (${searchTerm
          .split(' OR ')
          .map(
            (term) =>
              `__kfterms__${op}"${term
                .replace(/\\=/g, '=')
                .replace(/\\/g, '\\\\\\\\')}"`,
          )
          .join(' or ')})`,
      );
      return;
    }

    const withoutQuotes = getWithoutQuotes(searchTerm);

    if (op === '!=') {
      filter.grep.push(`!= "${withoutQuotes.replace(/\\/g, '\\\\\\\\')}"`);
      return;
    }

    filter.grep.push(`|= "${withoutQuotes.replace(/\\/g, '\\\\\\\\')}"`);
  });

  return filter;
};

/**
 * Build a logql filter from the filter by facets
 * @param filterByFacets
 * @returns
 */
const buildLogqlFilterByFacets = (
  filterByFacets: string[],
): { facet: string[]; label: string[] } => {
  const filter: { facet: string[]; label: string[] } = {
    facet: [],
    label: [],
  };

  const groupByOperator: { [key: string]: string[] } = {};
  filterByFacets.forEach((item: string) => {
    const [dataType, facetQueryStr] = item.split(delimiter);
    const dataTypeUpper = dataType.toUpperCase();

    const { operatorIndex, operator } = findFirstOperator(facetQueryStr);
    const rawFacetName = facetQueryStr.slice(0, operatorIndex);
    const hasAmpersand = rawFacetName[0] === '@';
    const rawFacetNameWithoutAmpersand = hasAmpersand
      ? rawFacetName.slice(1)
      : rawFacetName;
    const rawFacetNameParts = rawFacetNameWithoutAmpersand.split(':');

    const component = rawFacetNameParts.length > 1 ? rawFacetNameParts[0] : '';

    const facetName = rawFacetNameParts
      .slice(rawFacetNameParts.length > 1 ? 1 : 0)
      .join(':');

    const value = facetQueryStr.slice(operatorIndex + operator.length);

    const opSign = logsQueryOperator[operator];

    const facet = facetName.startsWith('@') ? facetName.slice(1) : facetName;
    let type = 'facet';

    if (component && !getIsComponentLabelStrict(component)) {
      filter.label.push(`source="${component}"`);
    }

    // split facet name if it's source:facet
    if (!hasAmpersand && getIsComponentLabelStrict(component)) {
      type = 'label';
    }

    const facetKey = `${facet}${delimiter}${opSign}${delimiter}${dataTypeUpper}${delimiter}${type}`;
    if (!groupByOperator[facetKey]) {
      groupByOperator[facetKey] = [];
    }
    // replace first and last double quotes
    const sanitizedValue = value.replace(/^"(.*)"$/, '$1');
    sanitizedValue.split(' OR ').forEach((sanitizedValueItem) => {
      groupByOperator[facetKey].push(sanitizedValueItem);
    });
  });

  const groupByOperatorKeys = Object.keys(groupByOperator);
  groupByOperatorKeys.forEach((key: string) => {
    const [facet, op, dataType, facetType] = key.split(delimiter);
    const values = groupByOperator[key];
    const operatorSign = getLogsOperatorSign(op);

    const dataTypes = ['duration', 'number'];
    const isNumeric = dataTypes.includes((dataType || '').toLowerCase());
    const doubleQuotes = isNumeric ? '' : '"';
    const type = facetType as 'facet' | 'label';

    if (op === 'eq' || op === 'neq') {
      if (isNumeric && type === 'facet') {
        filter[type].push(
          `(${values
            .map((value) => `${facet}${operatorSign}${value}`)
            .join(' or ')})`,
        );
        return;
      }

      const operator = getOperator(op, values);

      filter[type].push(
        `${facet}${operator}${doubleQuotes}${values.join('|')}${doubleQuotes}`,
      );
      return;
    }
    if (op === 'regex' || op === 'notregex') {
      // https://github.com/kloudfuse/ui/issues/2413
      // User will have to write proper regex.
      filter[type].push(`${facet}${operatorSign}"${values.join('|')}"`);
      return;
    }

    if (op === 'facetTermsExist' || op === 'notFacetTermsExist') {
      const facetTermsExistOperatorSign = op === 'facetTermsExist' ? '=' : '!=';
      filter[type].push(
        values
          .map(
            (value) =>
              `__kfterms__${facet}${facetTermsExistOperatorSign}"${value}"`,
          )
          .join(' or '),
      );
      return;
    }

    if (op === 'startsWith') {
      filter[type].push(`${facet}=~"${values.join('|')}.*"`);
      return;
    }

    if (op === 'endsWith') {
      filter[type].push(`${facet}=~".*${values.join('|')}"`);
      return;
    }

    if (op === 'contains') {
      filter[type].push(`${facet}=~".*${values.join('|')}.*"`);
      return;
    }

    values.forEach((value: string) => {
      filter[type].push(
        `${facet}${operatorSign}${doubleQuotes}${value}${doubleQuotes}`,
      );
    });
  });
  return filter;
};

/**
 * Build logql filter from sidebar filters
 * @param sidebarFilters
 */
const buildLogqlFilterBySidebarFilters = (
  selectedFacetValuesByName: SelectedFacetValuesByName,
) => {
  const groupByFacets: { [key: string]: string[] } = {};
  const facetKeys = Object.keys(selectedFacetValuesByName);

  facetKeys.forEach((facetKey: string) => {
    Object.keys(selectedFacetValuesByName[facetKey]).forEach((value) => {
      const [source, facetName, type] = facetKey.split(delimiter);
      const sanitizedFacetName = facetName.startsWith('@')
        ? facetName.slice(1)
        : facetName;

      const op =
        selectedFacetValuesByName[facetKey][value] === 1 ? 'eq' : 'neq';
      const facet = `${source}${delimiter}${sanitizedFacetName}${delimiter}${op}${delimiter}${type}`;
      if (!groupByFacets[facet]) {
        groupByFacets[facet] = [];
      }
      groupByFacets[facet].push(value);
    });
  });

  const filter: { label: string[]; facet: string[] } = {
    label: [],
    facet: [],
  };
  const groupByFacetsKeys = Object.keys(groupByFacets);
  groupByFacetsKeys.forEach((key: string) => {
    const [source, facet, op, dataType] = key.split(delimiter);
    const values = groupByFacets[key];
    const filterType = isSourceLabel(source) ? 'label' : 'facet';
    const dataTypes = ['duration', 'number'];
    const dataTypeNum = dataTypes.includes((dataType || '').toLowerCase());

    if ((op === 'eq' || op === 'neq') && values.length > 1 && !dataTypeNum) {
      filter[filterType].push(
        `${facet}${op === 'eq' ? '=' : '!'}~"${values.join('|')}"`,
      );
      return;
    }

    if (filterType === 'facet' && source) {
      filter.label.push(`source="${source}"`);
    }

    if (dataTypeNum) {
      values.forEach((value: string) => {
        filter[filterType].push(`${facet}${getOperatorSign(op)}${value}`);
      });

      return;
    }

    values.forEach((value: string) => {
      filter[filterType].push(`${facet}${getOperatorSign(op)}"${value}"`);
    });
  });

  return filter;
};

/**
 * Build logql filter from facet range
 * @param facetRange
 * @returns
 */
const buildLogqlFilterByFacetRange = (facetRanges: {
  [key: string]: {
    isLowerInclusive?: boolean;
    isUpperInclusive?: boolean;
    lower: number;
    upper: number;
  };
}) => {
  const filter: { facet: string[]; label: string[] } = {
    facet: [],
    label: [],
  };
  const facetRangeKeys = Object.keys(facetRanges);
  facetRangeKeys.forEach((key: string) => {
    const [source, facet, type] = key.split(delimiter);
    const unit = type && type.toLowerCase() === 'duration' ? 'ns' : '';
    const { isLowerInclusive, isUpperInclusive, lower, upper } =
      facetRanges[key];

    if (typeof lower === 'number') {
      const operator = isLowerInclusive ? '>=' : '>';
      filter.facet.push(`${facet}${operator}${lower}${unit}`);
    }

    if (typeof upper === 'number') {
      const operator = isUpperInclusive ? '<=' : '<';
      filter.facet.push(`${facet}${operator}${upper}${unit}`);
    }

    if (source) {
      filter.label.push(`source="${source}"`);
    }
  });

  return filter;
};

/**
 * Build logql filter from key exists
 * @param keyExists
 * @returns
 * @example keyExists = { source:!:facet:!:NUMBER: 1, source:!:facet:!:STRING: 0 }
 */
const buildLogqlFilterByKeyExists = (
  keyExists: LogQLBuilderFilterProps['keyExists'],
): { facet: string[]; label: string[] } => {
  if (!keyExists || Object.keys(keyExists).length === 0) {
    return { facet: [], label: [] };
  }

  const keyExistsFilter: string[] = [];
  const keyExistsKeysSource: string[] = [];
  const keyExistsKeys = Object.keys(keyExists);
  keyExistsKeys.forEach((key: string) => {
    const { component, name } = parseFacetKey(key);

    const nameParts = name.split(' OR ');

    if (keyExists[key] === 1) {
      const clauses = nameParts.map((name) => `${name} =~ ".+"`);
      keyExistsFilter.push(
        clauses.length > 1 ? `(${clauses.join(' or ')})` : clauses[0],
      );
    }

    if (keyExists[key] === 0) {
      const clauses = nameParts.map((name) => `${name} !~ ".+"`);
      keyExistsFilter.push(
        clauses.length > 1 ? `(${clauses.join(' or ')})` : clauses[0],
      );
    }

    if (component) {
      keyExistsKeysSource.push(`source="${component}"`);
    }
  });

  return { facet: keyExistsFilter, label: keyExistsKeysSource };
};

const addKeyExistsSourceToLabelFilter = (labelFilterArr: string[]) => {
  if (labelFilterArr.length === 0) {
    return 'source=~".+"';
  }

  const labelBitmap: { [key: string]: string[] } = {};

  labelFilterArr.forEach((item: string) => {
    const { operatorIndex, operator: op } = findFirstOperator(item);
    const key = item.slice(0, operatorIndex);
    const value = item.slice(operatorIndex + op.length);
    // remove first and last double quotes
    const keyWithOp = `${op}${delimiter}${key}`;
    const trimmedValue = value.replace(/^"(.*)"$/, '$1');
    if (!labelBitmap[keyWithOp]) {
      labelBitmap[keyWithOp] = [];
    }
    if (!labelBitmap[keyWithOp].includes(trimmedValue)) {
      labelBitmap[keyWithOp].push(trimmedValue);
    }
  });

  const labelBitmapKeys = Object.keys(labelBitmap);
  const labels: string[] = [];
  let shouldIncludeSourceFilter = true;
  labelBitmapKeys.forEach((keyWithOp: string) => {
    const values = labelBitmap[keyWithOp];
    const [op, key] = keyWithOp.split(delimiter);
    let operator = op;

    if (op === '=') {
      shouldIncludeSourceFilter = false;
    }

    if (values.length > 1) {
      if (op === '=') {
        operator = '=~';
      }

      if (op === '!=') {
        operator = '!~';
      }
      operator = values.length > 1 ? '=~' : '=';
    }

    const pipedValues = values.join('|');
    const realValues = pipedValues.split('|');

    labels.push(`${key}${operator}"${realValues.join('|')}"`);
  });

  return `${shouldIncludeSourceFilter ? 'source=~".+",' : ''}${labels.join(
    ',',
  )}`;
};

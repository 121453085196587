import classnames from 'classnames';
import { useUrlSearchParams } from 'hooks';
import React, { useState } from 'react';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import { ChevronRight } from 'react-feather';

const getCustomActive = ({ link, pathname, subItems }) => {
  if (link.route === pathname) {
    return true;
  }

  if (link.route === '/logs' || link.route === '/apm/services') {
    if (pathname === '/alerts') {
      return false;
    }
  }

  return subItems.reduce((acc: boolean, item: { route: string }) => {
    return item?.route === pathname || acc;
  }, false);
};

const HeaderCustomNavLink = ({ link, isSmallScreen, closeDrawer }: any) => {
  const { pathname } = useLocation();
  const navigate = useNavigate();
  const urlSearchParams = useUrlSearchParams();
  const subItems = link?.subItemsFunc
    ? link.subItemsFunc(navigate, urlSearchParams)
    : [];
  const [submenuHover, setSubmenuHover] = useState(false);

  const handleMouseEnter = () => {
    setSubmenuHover(true);
  };

  const handleMouseLeave = () => {
    setSubmenuHover(false);
  };

  return (
    <>
      <NavLink
        className={({ isActive }) =>
          isSmallScreen
            ? 'left-nav'
            : classnames({
                header__nav__item__link: true,
                'header__nav__item__link--active':
                  isActive || getCustomActive({ link, pathname, subItems }),
              })
        }
        to={link.route}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
        onClick={closeDrawer}
      >
        {link.icon}
        {link.label}
        {subItems.length > 0 && isSmallScreen && (
          <ChevronRight className="ml-auto size-4" />
        )}
      </NavLink>

      {isSmallScreen && submenuHover && subItems.length > 0 && (
        <div
          className="left-nav-bar-submenu-items"
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          {subItems.map((subItem) => (
            <NavLink
              key={subItem.route}
              to={subItem.route}
              className="left-nav-bar-submenu-subitem"
              onClick={closeDrawer}
            >
              <div className="left-nav-bar-submenu-subitem-inner">
                <div className="left-nav-bar-submenu-subitem-inner-icon">
                  {subItem.icon}
                </div>
                <div className="left-nav-bar-submenu-subitem-inner-text">
                  {subItem.label}
                </div>
              </div>
            </NavLink>
          ))}
        </div>
      )}
    </>
  );
};

export default HeaderCustomNavLink;

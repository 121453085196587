import dayjs from 'dayjs';
import { DateSelection, SelectedFacetValuesByName, TimeSeries } from 'types';
import { DataFrameMeta, DataTransformerConfig } from 'utils';
import queryRumService from './queryRumService';
import { buildRumFilter } from './utils';

type Args = {
  date: DateSelection;
  facetName?: string;
  filterByFacets?: { [key: string]: boolean };
  searchTerms?: string[];
  selectedFacetValuesByName?: SelectedFacetValuesByName;
  rollupSeconds: number;
  aggregateField: string;
  aggregateFunction: string;
  groupByField?: string;
  groupByLimit: number;
  transformer: DataTransformerConfig[];
  meta: DataFrameMeta;
};

const rumTimeSeries = async ({
  date,
  rollupSeconds,
  aggregateField,
  aggregateFunction,
  groupByField,
  groupByLimit,
  transformer,
  meta,
  selectedFacetValuesByName,
}: Args): Promise<TimeSeries[]> => {
  const { startTimeUnix, endTimeUnix } = date;
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  return queryRumService<any, 'timeSeries'>(`
  query {
    timeSeries(
      eventType: VIEW,
      timestamp: "${endTime.format()}",
      durationSecs: ${durationSecs},
      rollupSeconds: ${rollupSeconds},
      aggregate: {
        field: "${aggregateField}",
        aggregation: "${aggregateFunction}",
      }
      filter: ${buildRumFilter({
        selectedFacetValuesByName,
      })}
     
        
    ) {
      dimensions,
      points {
        ts,
        value,
      }
    }
  }
  `).then((data) => {
    const initialData = { datasets: data.timeSeries, meta };
    const transformed = transformer.reduce(
      (prevData, item) => item.func(prevData),
      initialData,
    );
    return transformed as unknown;
  });
};

export default rumTimeSeries;

import React, { useEffect, useState } from 'react';
import { NavigateFunction } from 'react-router-dom';
import HeaderCustomNavLink from 'screens/Header/HeaderCustomNavLink';
import { useAlertsPageStateContext } from 'context';
import { useUrlSearchParams } from 'hooks';
import { UserRole } from 'types/user';
import { ReactNode } from 'react';
import classNames from 'classnames';
import { RxHamburgerMenu } from 'react-icons/rx';

type Link = {
  key: string;
  label: string;
  panel?: ReactNode;
  route?: string;
  subItemsFunc?: (
    navigate?: NavigateFunction,
    urlSearchParams?: URLSearchParams,
  ) => any[];
};

type GetLinksParams = {
  alertsPageState: ReturnType<typeof useAlertsPageStateContext>;
  enableLogsSaveMetrics: boolean;
  enableTraceSaveMetrics: boolean;
  isAdmin: boolean;
  urlSearchParams: URLSearchParams;
  workbooks: any[];
  showASM?: boolean;
  showRUM?: boolean;
  showServerless: boolean;
  showKfuseProfiler: boolean;
};

type GetLinks = (params: GetLinksParams) => Link[];
type GetLinksRight = (urlSearchParams: URLSearchParams) => Link[];

type LeftNavBarProps = {
  isSmallScreen: boolean;
  workbooks: any[];
  user: any;
  enableSaveMetrics: boolean;
  enableTraceSaveMetrics: boolean;
  showServerless: boolean;
  showASM: boolean;
  showRUM: boolean;
  showKfuseProfiler: boolean;
  getLinks: GetLinks;
  getLinksRight: GetLinksRight;
};

const LeftNavBar: React.FC<LeftNavBarProps> = ({
  isSmallScreen,
  workbooks,
  user,
  enableSaveMetrics,
  enableTraceSaveMetrics,
  showServerless,
  showASM,
  showRUM,
  showKfuseProfiler,
  getLinks,
  getLinksRight,
}) => {
  const alertsPageState = useAlertsPageStateContext();
  const urlSearchParams = useUrlSearchParams();
  const [hovering, setHovering] = useState(false);

  const handleMouseEnter = () => {
    setHovering(true);
  };

  const handleMouseLeave = () => {
    setHovering(false);
  };

  return (
    <div>
      <button
        className="left-nav-bar-hamburger-button"
        onMouseEnter={handleMouseEnter}
        onMouseLeave={handleMouseLeave}
      >
        <RxHamburgerMenu size={24} />
      </button>
      {hovering && (
        <div
          className={classNames('left-nav-bar-menu', {
            'left-nav-bar-menu-open': hovering,
          })}
          onMouseEnter={handleMouseEnter}
          onMouseLeave={handleMouseLeave}
        >
          <div className="left-nav-bar-submenu">
            {getLinks({
              alertsPageState,
              enableLogsSaveMetrics: enableSaveMetrics,
              enableTraceSaveMetrics: enableTraceSaveMetrics,
              isAdmin: user?.role === UserRole.ADMIN,
              urlSearchParams,
              workbooks,
              showServerless,
              showASM,
              showRUM,
              showKfuseProfiler,
            }).map((link) => {
              const emailDisablingAdmin = 'noreply@kloudfuse.com';
              const userEmailIsDisablingAdmin =
                user.email === emailDisablingAdmin;
              const routeContainingAdmin = link.route.includes('/admin');
              const displayTab = !(
                routeContainingAdmin && userEmailIsDisablingAdmin
              );

              return (
                displayTab && (
                  <div key={link.key} className="drawer-link">
                    <HeaderCustomNavLink
                      link={link}
                      isSmallScreen={isSmallScreen}
                      isSubMenuVisible={true}
                      closeDrawer={() => setHovering(false)}
                    />
                  </div>
                )
              );
            })}
            {getLinksRight(urlSearchParams).map((link, index) => (
              <div key={link.key} className="drawer-link">
                <HeaderCustomNavLink
                  link={link}
                  isSmallScreen={isSmallScreen}
                  isSubMenuVisible={true}
                  closeDrawer={() => setHovering(false)}
                />
              </div>
            ))}
          </div>
        </div>
      )}
    </div>
  );
};
export default LeftNavBar;

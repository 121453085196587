import { FilterType, Operator } from 'hooks/useFiltersState';
import {
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
} from 'types';
import { getFacetKey } from 'utils/facets';

const getCustomerFilterForQuery = ({
  customerFilter,
}: {
  customerFilter: { key: string; value: string };
}) => {
  return customerFilter &&
    customerFilter.key &&
    customerFilter.value &&
    customerFilter.value !== 'All'
    ? [
        {
          type: FilterType.selectedFacetValue,
          value: {
            facet: getFacetKey({
              component: '',
              name: customerFilter.key,
              type: 'string',
            }),
            operator: Operator.equal,
            values: {
              [customerFilter.value]: 1,
            },
          },
        },
      ]
    : [];
};

export const onCreateAlertLogs = ({
  customerFilter,
  date,
  queryItem,
}: {
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  queryItem: {
    formulas?: LogsMetricForumlaProps[];
    queries: LogsMetricQueryProps[];
    queryIndex?: number;
  };
}) => {
  const { formulas, queries, queryIndex } = queryItem;
  if (queryIndex !== undefined) {
    const query = {
      ...queries[queryIndex],
      filters: [
        ...(queries[queryIndex].filters || []),
        ...getCustomerFilterForQuery({ customerFilter }),
      ],
    };
    const encodeQueryURI = encodeURIComponent(JSON.stringify([query]));

    window.open(
      `/#/alerts/create?ruleType=${encodeURIComponent(
        JSON.stringify({ value: 'logs' }),
      )}&LogsMetricsQueries=${encodeQueryURI}&date=${encodeURIComponent(
        JSON.stringify(date),
      )}`,
      '_blank',
    );
    return;
  }

  let newQueries = queries.map((query) => ({
    ...query,
    filters: [
      ...(query.filters || []),
      ...getCustomerFilterForQuery({ customerFilter }),
    ],
  }));
  if (newQueries.length === 1 && formulas.length === 0) {
    newQueries[0] = { ...newQueries[0], queryKey: 'a' };
  }
  if (formulas && formulas.length > 0) {
    newQueries = newQueries.map((query) => ({ ...query, isActive: false }));
  }

  const encodeQueryURI = encodeURIComponent(JSON.stringify(newQueries));
  window.open(
    `/#/alerts/create?ruleType=${encodeURIComponent(
      JSON.stringify({ value: 'logs' }),
    )}&LogsMetricsQueries=${encodeQueryURI}&date=${encodeURIComponent(
      JSON.stringify(date),
    )}&LogsMetricsFormulas=${encodeURIComponent(JSON.stringify(formulas))}`,
    '_blank',
  );
  return;
};

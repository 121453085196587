import camelcaseKeys from 'camelcase-keys';
import classnames from 'classnames';
import {
  DateControls,
  DateWarningAlert,
  LeftSidebar,
  Loader,
  ProductTour,
  SelectV2,
  ShowSidebarTooltipButton,
  SpanFilters,
  TraceSidebar,
  useLeftSidebarState,
} from 'components';
import { Datepicker } from 'composite';
import { useFeatureFlag } from 'configcat-react';
import {
  useLiveTail,
  useRequest,
  useTracesPageState,
  useUrlState,
} from 'hooks';
import React, { useEffect, useMemo, useState } from 'react';
import {
  getServiceGroupLabelValues,
  getServiceGroupLabels,
  getServices,
  traceLabelNamesBase,
} from 'requests';
import {
  DateSelection,
  SelectedFacetValuesByName,
  SpanFilter,
  Trace,
  TracesTab,
  VisualizeAs,
} from 'types';
import { getColorsByServiceName } from 'utils';
import TracesChartGrid from './TracesChartGrid';
import TracesSearch from './TracesSearch';
import TracesSidebar from './TracesSidebar';
import TracesTable from './TracesTable';
import TracesTimeseries from './TracesTimeseries';
import { areFiltersEqual } from './utils';
import CustomerFilter from 'screens/Services/CustomerFilter';
import useDebouncedEffect from 'use-debounced-effect';
import { IoIosWarning } from 'react-icons/io';

type Props = {
  tracesPageState: ReturnType<typeof useTracesPageState>;
  tracesTab: TracesTab;
};

const Traces = ({ tracesPageState, tracesTab }: Props) => {
  const activeTraceState = useUrlState<Trace>('activeTrace', null);
  const {
    chartGridKeysState,
    dependenciesForWriteStateToUrl,
    searches,
    searchesFormulas,
    tracesState,
    writeStateToUrl,
  } = tracesPageState;
  const [activeTrace, setActiveTrace] = activeTraceState;
  // TODO(baibhav): URL should update to "live-tail=true" like Logs
  const liveTail = useLiveTail(
    '/trace/livetail?traceFilter=%7B%7D',
    camelcaseKeys,
  );
  const { value: showService7Day } = useFeatureFlag('showservice7day', false);
  const {
    value: isCustomerFilteringEnabled,
    loading: isCustomerFilteringConfigLoading,
  } = useFeatureFlag('isCustomerFilteringEnabled', false);

  const isLiveTailEnabled = liveTail?.isEnabled;
  const search = searches[0];
  const getServicesRequest = useRequest(getServices, true, true);
  const leftSidebarState = useLeftSidebarState('traces');
  const traceLabelNamesRequest = useRequest(traceLabelNamesBase, true, true);
  const { customerFilter, dateState, setCustomerFilter, spanFilters } =
    tracesState;
  const [date, setDate] = dateState;
  const { spanFilter, setSpanFilter } = spanFilters;

  const [error, setError] = useState({
    getGroupLabel: null,
  });

  const getServiceGroupLabelRequest = useRequest(
    getServiceGroupLabels,
    true,
    true,
  );
  const getServiceGroupValuesRequest = useRequest(
    getServiceGroupLabelValues,
    true,
    true,
  );

  const isCustomerFilterSelected = Boolean(customerFilter?.value);

  const showTracesLandingPage =
    !isCustomerFilteringEnabled || isCustomerFilterSelected;

  useEffect(() => {
    return () => {
      liveTail.closeSocket();
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  useDebouncedEffect(
    () => {
      if (showTracesLandingPage) {
        const selectedFacetValuesByName = {
          span_type: { db: 0 },
        };
        getServicesRequest.call({
          customerFilter,
          date,
          selectedFacetValuesByName:
            selectedFacetValuesByName as SelectedFacetValuesByName,
        });
        traceLabelNamesRequest.call({ date });
        liveTail.stopLiveTail();
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    {
      ignoreInitialCall: false,
      timeout: 50,
    },
    [customerFilter, showTracesLandingPage, date],
  );

  useEffect(() => {
    const selectedIndex = tracesState.selectedQueryIndexString || '0';
    const indexNum = Number(selectedIndex);
    if (!searches[indexNum]) {
      return;
    }
    if (searches[indexNum].searchBarState) {
      const filterOfSearch = searches[indexNum].searchBarState['filters'];
      const traceIdSearchOfSearch =
        searches[indexNum].searchBarState['traceIdSearch'];

      const areFiltersSame = areFiltersEqual({
        filters1: filterOfSearch,
        filters2: tracesState.filtersState.state,
      });

      if (
        !areFiltersSame ||
        traceIdSearchOfSearch !== tracesState.traceIdSearch
      ) {
        const parsedFilters = JSON.parse(JSON.stringify(filterOfSearch));

        tracesState.filtersState.setState(parsedFilters);
        tracesState.setTraceIdSearch(traceIdSearchOfSearch);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tracesState.selectedQueryIndexString]);

  const serviceByHash = useMemo(
    () =>
      (getServicesRequest.result || []).reduce(
        (obj, service) => ({ ...obj, [service.hash]: service }),
        {},
      ),
    [getServicesRequest.result],
  );

  const colorsByServiceName = useMemo(
    () => getColorsByServiceName(getServicesRequest.result || []),
    [getServicesRequest.result],
  );

  const showUseFacetsOptionOnSideBar = useMemo(() => {
    return tracesTab !== TracesTab.list && searches.length > 1;
  }, [tracesTab, searches]);

  const facetsOfOptions = useMemo(() => {
    return searches.map((query, index) => {
      const value = query.queryKey;
      return {
        label: `Query ${value}`,
        value: String(index),
      };
    });
  }, [searches]);

  const close = () => {
    setActiveTrace(null);
  };

  useEffect(() => {
    if (isCustomerFilteringEnabled) {
      getServiceGroupLabelRequest
        .call()
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({ ...prevError, getGroupLabel: null }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getGroupLabel: { message: 'Failed to fetch group label' },
          }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isCustomerFilteringEnabled]);

  const selectedLabel = useMemo(() => {
    if (
      getServiceGroupLabelRequest.result &&
      getServiceGroupLabelRequest.result.length
    ) {
      const firstLabel = getServiceGroupLabelRequest.result[0];
      return firstLabel;
    }
    return null;
  }, [getServiceGroupLabelRequest.result]);

  useDebouncedEffect(
    () => {
      if (selectedLabel) {
        getServiceGroupValuesRequest.call({ label: selectedLabel });
      }
    },
    50,
    [selectedLabel],
  );

  if (isCustomerFilteringConfigLoading) {
    return <Loader className="h-full" isLoading={true} />;
  }

  return (
    <div
      className={classnames({
        traces: true,
        'traces--disable-7-day': !showService7Day,
      })}
    >
      {!isLiveTailEnabled && (
        <LeftSidebar leftSidebarState={leftSidebarState}>
          {isCustomerFilteringEnabled && (
            <CustomerFilter
              customerFilter={customerFilter}
              filterLabel={selectedLabel}
              valueOptions={getServiceGroupValuesRequest.result || []}
              isLoading={getServiceGroupValuesRequest.isLoading}
              setCustomerFilter={setCustomerFilter}
              error={error}
            />
          )}
          {showTracesLandingPage && (
            <TracesSidebar
              colorsByServiceName={colorsByServiceName}
              customerFilter={customerFilter}
              facetsOfOptions={facetsOfOptions}
              showUseFacetsOptionOnSideBar={showUseFacetsOptionOnSideBar}
              serviceByHash={serviceByHash}
              traceLabelNamesRequest={traceLabelNamesRequest}
              tracesState={tracesState}
            />
          )}
        </LeftSidebar>
      )}
      {showTracesLandingPage ? (
        <>
          <div className="traces__main overflow-auto">
            {!isLiveTailEnabled ? (
              <DateWarningAlert className="traces__banner" date={date} />
            ) : null}
            <div className="traces__header">
              <div className="traces__header__left">
                <TracesSearch
                  colorsByServiceName={colorsByServiceName}
                  leftSidebarState={leftSidebarState}
                  placeholder="Search for any tag on ingested spans"
                  searches={searches}
                  searchesFormulas={searchesFormulas}
                  serviceByHash={serviceByHash}
                  traceLabelNamesRequest={traceLabelNamesRequest}
                  tracesState={tracesState}
                  tracesTab={tracesTab}
                  isLiveTailEnabled={isLiveTailEnabled}
                />
              </div>
              <div className="traces__header__right">
                <div className="traces__header__date-controls">
                  <div className="traces__header__date-controls__item">
                    <Datepicker
                      hasStartedLiveTail={liveTail.isEnabled}
                      onChange={setDate}
                      startLiveTail={liveTail.startLiveTailIfNeeded}
                      value={date as DateSelection}
                    />
                  </div>
                  <div className="traces__header__date-controls__item">
                    <DateControls
                      date={date}
                      liveTail={liveTail}
                      setDate={setDate}
                    />
                  </div>
                </div>
                <div className="traces__header__filters">
                  {search.visualizeAs === VisualizeAs.flowMap ? (
                    <SelectV2.Select
                      className={classnames({
                        'select--disabled': true,
                      })}
                      onChange={setSpanFilter}
                      options={Object.values(SpanFilter).map(
                        (spanFilterLabel) => ({
                          label: spanFilterLabel,
                          value: spanFilterLabel,
                        }),
                      )}
                      right
                      value={
                        tracesTab === TracesTab.serviceMap
                          ? SpanFilter.traceRootSpans
                          : spanFilter
                      }
                    />
                  ) : (
                    <SpanFilters spanFilters={spanFilters} />
                  )}
                </div>
              </div>
            </div>
            {tracesTab === TracesTab.list ? (
              <TracesChartGrid
                customerFilter={customerFilter}
                tracesState={tracesState}
              />
            ) : null}
            <div className="traces__main overflow-auto">
              {tracesTab === TracesTab.list ? (
                <TracesTable
                  colorsByServiceName={colorsByServiceName}
                  customerFilter={customerFilter}
                  liveTail={liveTail}
                  setActiveTrace={setActiveTrace}
                  tracesState={tracesState}
                />
              ) : null}
              {tracesTab !== TracesTab.list ? (
                <TracesTimeseries
                  customerFilter={customerFilter}
                  searches={searches}
                  searchesFormulas={searchesFormulas}
                  serviceByHash={serviceByHash}
                  tracesState={tracesState}
                  tracesTab={tracesTab}
                  isLiveTailEnabled={isLiveTailEnabled}
                />
              ) : null}
            </div>
          </div>
          {activeTrace ? (
            <TraceSidebar
              chartGridKeysState={chartGridKeysState}
              close={close}
              colorsByServiceName={colorsByServiceName}
              key={activeTrace.span.spanId}
              trace={activeTrace}
              tracesState={tracesState}
            />
          ) : null}
        </>
      ) : (
        <div className="flex flex-col height-100-per w-full">
          {leftSidebarState.width === 0 ? (
            <div className="flex justify-start">
              <ShowSidebarTooltipButton
                className="customer-filter__show-sidebar"
                onClick={leftSidebarState.show}
              />
              <div />
            </div>
          ) : null}
          <div className="flex-1 flex items-center justify-center">
            <div className="facet-picker__values__placeholder italic text-2xl">
              Select Service Group Filter To View the Traces
            </div>
          </div>
        </div>
      )}

      <ProductTour />
    </div>
  );
};

export default Traces;

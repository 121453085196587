import {
  AnalyticsChartQueryProps,
  AnalyticsChartFullscreenProps,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
} from 'components';
import {
  SearchState,
  getLegacyFiltersFromFiltersState,
  useRequest,
  useSearches,
} from 'hooks';
import React, { useMemo } from 'react';
import useRumState from 'screens/Rum/hooks/useRumState';
import { RumEventType, RumTab } from 'screens/Rum';
import {
  ChartType,
  DashboardPanelType,
  DateSelection,
  QueryDataProps,
  SelectedFacetValuesByName,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
//Todo: Analytics Yet to Complete for RUM
import {
  DataFrameMeta,
  DataTransformerConfig,
  sortAndLimitTimeseries,
} from 'utils/DataTransformer';
import aggregateRumTimeSeries from 'screens/Rum/requests/aggregateRumTimeSeries';
import onPromiseError from 'utils/onPromiseError';
import { getRollupByVisualization, getRollupToSecond } from 'utils/rollup';
import aggregateRumTable from 'screens/Rum/requests/aggregateRumTable';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';

type Props = {
  searches: ReturnType<typeof useSearches>['searches'];
  rumState: ReturnType<typeof useRumState>;
  rumTab: RumTab;
};

const limitToMap: Record<string, 'topk' | 'bottomk'> = {
  top: 'topk',
  bottom: 'bottomk',
};

const fetchAnalytics = async ({
  date,
  dataFormat,
  eventType,
  transformer,
  query,
  selectedFacetValuesByName,
}: {
  date: DateSelection;
  dataFormat: DashboardPanelType;
  eventType: RumEventType;
  transformer: DataTransformerConfig[];
  query: SearchState;
  selectedFacetValuesByName: SelectedFacetValuesByName;
}) => {
  const step = query.rollUpInSeconds
    ? getRollupToSecond(query.rollUpInSeconds as unknown as string)
    : getRollupByVisualization(date);

  const data =
    dataFormat === DashboardPanelType.TIMESERIES
      ? await aggregateRumTimeSeries({
          ...query,
          aggregation: query.operation,
          aggregationField: query.measure || '*',
          date,
          eventType,
          rollUpSeconds: step,
          selectedFacetValuesByName: selectedFacetValuesByName,
        }).catch(onPromiseError)
      : await aggregateRumTable({
          ...query,
          aggregation: query.operation,
          aggregationField: query.measure || '*',
          date,
          eventType,
          rollUpSeconds: step,
          selectedFacetValuesByName: selectedFacetValuesByName,
        }).catch(onPromiseError);

  const metricName =
    query.operation === 'distinctcount' ? 'count' : query.operation;
  const meta: DataFrameMeta = {
    refId: query.queryKey,
    step,
    type: dataFormat,
    labels: query.groupBys,
    metricName,
    aggregate: metricName,
    executedDate: date,
    queryType: 'query',
    unit: query.measure === 'duration_ns' ? 'ns' : 'number',
  };
  const initialData = { datasets: data, meta };

  const limit = {
    count: query.limitToValue,
    direction: limitToMap[query.limitTo],
  };

  const sortAndLimitTransformer = {
    id: 'sortAndLimitTimeseries',
    func: (dataFrame: any) => sortAndLimitTimeseries(dataFrame, limit),
  };
  transformer.splice(1, 0, sortAndLimitTransformer);
  const transformed = transformer.reduce(
    (acc: any, currentTransformer: { func: (arg0: any) => any }) => {
      return currentTransformer.func(acc);
    },
    initialData,
  );
  return transformed;
};

const useRumTimeseries = ({ searches, rumState, rumTab }: Props) => {
  const { dateState, eventType, selectedFacetValuesByNameState } = rumState;
  const modals = useModalsContext();

  const analyticsChart = useAnalyticsChart({
    date: dateState[0],
    forcedActiveVisualization: rumTab as unknown as DashboardPanelType,
    hideVisualizeToolBar: true,
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;

  const aggregateTimeSeriesMultiple = useRequest((args) => {
    return fetchAnalytics(args);
  });

  const [date, setDate] = dateState;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;

  const queries = searches.map((s) => s.state);

  const filter = useMemo(() => {
    return {
      selectedFacetValuesByName,
      selectedHcFacetValuesByName: {},
    };
  }, [selectedFacetValuesByName]);

  const rangeKey = activeVisualization === 'timeseries' ? 'range' : 'instant';

  const onViewFullscreen = ({
    activeChart,
    chartQueries,
    chartFormulas,
    prevChartData,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    modals.push(
      <TimeseriesExplorer
        activeQueries={[...chartQueries, ...chartFormulas]}
        activeChartType={activeChart as ChartType}
        chartData={prevChartData}
        date={date}
        queryType="rum-performance"
        onClose={modals.pop}
        unit={unit}
      />,
    );
  };

  const analyticsData = useMemo(() => {
    if (!aggregateTimeSeriesMultiple.result) {
      return { chartData: {}, chartQueries: [], formulaQueries: [] };
    }

    const datasets = aggregateTimeSeriesMultiple.result;
    const isLoading = aggregateTimeSeriesMultiple.isLoading;
    const chartData: QueryDataProps = {};
    const chartQueries: AnalyticsChartQueryProps[] = [];

    searches.forEach((search, idx) => {
      const queryId = `query_${search.queryKey}`;
      const dataset = datasets;

      if (!dataset) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (isLoading) {
        chartData[queryId] = { [rangeKey]: null, isLoading, meta: null };
        return;
      }

      if (activeVisualization !== dataset.meta?.type) {
        return;
      }

      chartData[queryId] = {
        [rangeKey]: dataset,
        isLoading,
        meta: dataset.meta,
      };
    });

    searches.forEach((search, idx) => {
      const query = search.state;
      const { keyExists, selectedFacetRangeByName, selectedFacetValuesByName } =
        getLegacyFiltersFromFiltersState([]);
      const filter = {
        keyExists,
        selectedFacetRangeByName,
        selectedFacetValuesByName,
      };
      chartQueries.push({
        direction: query.limitTo,
        filter,
        index: idx,
        isActive: query.isActive,
        type: 'query',
        query,
        queryKey: query.queryKey,
        steps: query.rollUpInSeconds,
        vectorAggregate:
          query.operation === 'distinctcount' ? 'count' : query.operation,
        unit: query.measure === 'duration_ns' ? 'ns' : 'number',
      });
    });

    return { chartData, chartQueries, formulaQueries: [] };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    aggregateTimeSeriesMultiple.result,
    aggregateTimeSeriesMultiple.isLoading,
    searches,
    rangeKey,
    filter,
    activeVisualization,
  ]);

  const groupBysForTable = useMemo(() => {
    const res: string[] = [];
    queries
      .filter((item) => item.isActive)
      .map((q) => {
        const gr: string[] = [];
        q.groupBys.map((g) => {
          g !== '*' && gr.push(g);
        });
        res.push(...gr);
      });

    return res;
  }, [queries]);

  const loadRumTimeseries = () => {
    const instant = activeVisualization !== 'timeseries';
    const baseTransformers = rumDataTransformer(instant);

    const preparedQueries = searches.map((s) => s.state);

    aggregateTimeSeriesMultiple.call({
      date,
      eventType,
      dataFormat: activeVisualization,
      formulas: [],
      instant,
      query: preparedQueries[0],
      transformer: baseTransformers,
      selectedFacetValuesByName,
    });
  };

  useDebouncedEffect(
    () => {
      loadRumTimeseries();
    },
    {
      timeout: 100,
      ignoreInitialCall: false,
    },
    [activeVisualization, date, eventType, searches, selectedFacetValuesByName],
  );

  return {
    analyticsChart,
    analyticsData,
    groupBysForTable,
    onViewFullscreen,
  };
};

export default useRumTimeseries;

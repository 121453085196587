import { delimiter } from 'kfuse-constants';
import { FavoriteFacet } from 'types';

export const getFacetKey = (facet: FavoriteFacet): string => {
  const { component, name, type, displayName } = facet;
  return `${component}${delimiter}${name}${delimiter}${type}${delimiter}${displayName || ''}`;
};

export const parseFacetKey = (facetKey: string): FavoriteFacet => {
  const [component, name, type, displayName] = facetKey.split(delimiter);
  return {
    component,
    name,
    type,
    dataType: type,
    displayName,
    source: component,
  };
};

import {
  AnalyticsChartQueryProps,
  AnalyticsChartFullscreenProps,
  DashboardExport,
  TimeseriesExplorer,
  useAnalyticsChart,
  useModalsContext,
  useToaster,
} from 'components';
import { useLogsMetricsQueryState } from 'hooks';
import delimiter from 'kfuse-constants/delimiter';
import React, { useMemo, useState } from 'react';
import {
  DashboardPanelType,
  DateSelection,
  LabelsProps,
  LogsMetricQueryProps,
} from 'types';
import useDebouncedEffect from 'use-debounced-effect';
import {
  logsQueryTransformForAnnotations,
  parseFacetKey,
  logqlErrorMessage,
  parseErrorFromQueryData,
} from 'utils';

import LogsAnalyticsSaveMetrics from '../LogsAnalyticsSaveMetrics';
import {
  getLogqlForChartModal,
  getLogQLDashboardExportPanel,
  onCreateAlertLogs,
} from '../utils';

type Args = {
  customerFilter: { key: string; value: string };
  date: DateSelection;
  labels: LabelsProps;
  queriesState: ReturnType<typeof useState<LogsMetricQueryProps[]>>;
  tab: string;
};

const useLogsAnalyticsChart = ({
  customerFilter,
  date,
  labels,
  queriesState,
  tab,
}: Args) => {
  const modal = useModalsContext();
  const { addToast } = useToaster();

  const analyticsChart = useAnalyticsChart({
    date,
    forcedActiveVisualization: tab as DashboardPanelType,
    hideVisualizeToolBar: true,
    supportedVisualizations: [
      DashboardPanelType.TIMESERIES,
      DashboardPanelType.TOP_LIST,
      DashboardPanelType.TABLE,
      DashboardPanelType.PIECHART,
    ],
  });
  const { activeVisualization } = analyticsChart;
  const { chartWidth } = analyticsChart;
  const logsMetricsQueryState = useLogsMetricsQueryState({
    chartWidth,
    customerFilter,
    date,
    isLogsPage: true,
    labels,
    dataFormat: activeVisualization,
    isRange: activeVisualization === DashboardPanelType.TIMESERIES,
    queriesState,
  });
  const { formulas, queries } = logsMetricsQueryState;

  const openSaveMetricModal = ({ index, type }: AnalyticsChartQueryProps) => {
    const query = queries[index];
    const formula = formulas[index];

    if (!query) {
      addToast({ status: 'error', text: 'Invalid query or formula' });
      return;
    }

    if (type === 'query' && query.showInput && !query.logql) {
      addToast({ status: 'error', text: 'LogQL query cannot be empty' });
      return;
    }

    const logqlForSave = getLogqlForChartModal({
      customerFilter,
      formula,
      query,
      queries,
      logqlType: 'save',
      type,
    });

    if (!logqlForSave) {
      addToast({ status: 'error', text: 'Not a valid LogQL query to save' });
      return;
    }
    modal.push(
      <LogsAnalyticsSaveMetrics
        closeModal={modal.pop}
        logqlText={logqlForSave}
        query={type === 'query' ? query : undefined}
      />,
    );
  };

  const openExportToDashboardModal = ({
    dashboardType = DashboardPanelType.TIMESERIES,
    index,
    type,
  }: AnalyticsChartQueryProps) => {
    const query = queries[index];
    const formula = formulas[index];
    const { showInput, logql } = query;
    if (showInput && !logql) {
      addToast({
        status: 'error',
        text: 'LogQL query cannot be empty to create dashboard',
      });
      return;
    }

    const logqlForLoader = getLogqlForChartModal({
      customerFilter,
      formula,
      query,
      logqlType: 'load',
      queries,
      type: type,
    });

    if (!logqlForLoader) {
      addToast({
        status: 'error',
        text: 'Not a valid LogQL query to create dashboard',
      });
      return;
    }

    modal.push(
      <DashboardExport
        annotations={logsQueryTransformForAnnotations({
          customerFilter,
          queries,
          formulas,
          queryIndex: index,
          type,
        })}
        closeModal={modal.pop}
        date={date}
        panel={getLogQLDashboardExportPanel({
          logql: logqlForLoader,
          type: dashboardType,
        })}
      />,
    );
  };

  const onViewFullscreen = ({
    activeChart,
    prevChartData,
    chartQueries,
    chartFormulas,
    unit,
  }: AnalyticsChartFullscreenProps) => {
    const logqlForQuery = chartQueries?.map((query) =>
      getLogqlForChartModal({
        customerFilter,
        query: queries[query.index],
        logqlType: 'load',
        type: 'query',
      }),
    );
    const logqlFormula = chartFormulas?.map((formula) =>
      getLogqlForChartModal({
        customerFilter,
        formula: formulas[formula.index],
        logqlType: 'load',
        queries,
        type: 'formula',
      }),
    );
    const activeQueries = logqlForQuery.map((logql: string, idx: number) => {
      return { logql, steps: queries[idx].step };
    });
    logqlFormula.forEach((logql: string) => {
      activeQueries.push({ logql, steps: queries[0].steps });
    });

    modal.push(
      <TimeseriesExplorer
        activeQueries={activeQueries}
        activeChartType={activeChart}
        chartData={prevChartData}
        date={date}
        queryType="logql"
        onClose={modal.pop}
        unit={unit}
      />,
    );
  };

  const onClickCreateAlertLogs = ({
    chartFormulas,
    chartQueries,
  }: {
    chartQueries: AnalyticsChartFullscreenProps['chartQueries'];
    chartFormulas: AnalyticsChartFullscreenProps['chartFormulas'];
  }) => {
    const queriesToAlert = chartQueries.map((query) => {
      return queries[query.index];
    });
    const formulasToAlert = chartFormulas.map((formula) => {
      return formulas[formula.index];
    });

    onCreateAlertLogs({
      customerFilter,
      date,
      queryItem: { queries: queriesToAlert, formulas: formulasToAlert },
    });
  };

  const chartQueries = useMemo(() => {
    if (!queries || queries.length === 0) return [];
    return queries.map((query, index: number) => {
      const qlogql = getLogqlForChartModal({
        customerFilter,
        query,
        logqlType: 'load',
        type: 'query',
      });
      const { isActive, normalizeFunction, queryKey, vectorAggregate } = query;
      const unit = normalizeFunction === 'duration' ? 's' : normalizeFunction;
      return {
        direction: query.limit.direction === 'topk' ? 'top' : 'bottom',
        isActive,
        index,
        logql: qlogql,
        queryKey,
        type: 'query',
        unit,
        vectorAggregate,
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries]);

  const chartFormulas = useMemo(() => {
    if (!formulas || formulas.length === 0) return [];

    return formulas.map((formula, index: number) => {
      const flogql = getLogqlForChartModal({
        customerFilter,
        formula,
        logqlType: 'load',
        queries,
        type: 'formula',
      });
      const { isActive, queryKey } = formula;
      return {
        direction: 'top',
        isActive,
        index,
        logql: flogql,
        queryKey,
        type: 'formula',
        unit: 'number',
      };
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formulas]);

  const groupBysForTable = useMemo(() => {
    if (!queries || queries.length === 0) return [];
    const res: string[] = [];
    queries
      .filter((query) => query.isActive)
      .map(({ rangeAggregateGrouping }: LogsMetricQueryProps) => {
        const gr: string[] = [];
        const isLabel = res.find((g) => g === 'label');
        if (!isLabel && rangeAggregateGrouping.length === 0) {
          gr.push('label');
        }

        rangeAggregateGrouping.forEach((g) => {
          const name = g.startsWith('@')
            ? g.substring(1).split(delimiter)[0]
            : parseFacetKey(g).name;
          gr.push(name);
        });
        res.push(...gr);
      });
    return res;
  }, [queries]);

  useDebouncedEffect(
    () => {
      if (tab === 'chart') {
        if (activeVisualization === 'timeseries') {
          logsMetricsQueryState.loadMultipleLogsChartData({
            formulas,
            queries,
            chartWidth,
          });
          return;
        }
        logsMetricsQueryState.loadInstantMultipleLogsChartData({
          chartWidth,
          queries,
          formulas,
        });
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    },
    100,
    [activeVisualization, tab],
  );

  const logsAnalyticsOverlayMessage = useMemo(() => {
    return parseErrorFromQueryData({
      queryData: logsMetricsQueryState.logsChartData,
      queries: queries,
      formulas: formulas,
      dataType:
        activeVisualization === DashboardPanelType.TIMESERIES
          ? 'range'
          : 'instant',
      customErrorMessage: logqlErrorMessage,
    });
  }, [
    activeVisualization,
    queries,
    formulas,
    logsMetricsQueryState.logsChartData,
  ]);

  return {
    analyticsChart,
    chartFormulas,
    chartQueries,
    chartWidth,
    groupBysForTable,
    logsMetricsQueryState,
    onClickCreateAlertLogs,
    openSaveMetricModal,
    openExportToDashboardModal,
    onViewFullscreen,
    logsAnalyticsOverlayMessage,
  };
};

export default useLogsAnalyticsChart;

import { Maybe } from 'types';
import { parseGraphQlError } from 'utils';

import fetchJson from './fetchJson';

type Response = {
  errors?: any[];
  data?: any;
};

const query = <T, Key extends string | number | symbol>(
  query: string,
): Promise<Record<Key, Maybe<T>>> =>
  fetchJson('/query', {
    method: 'POST',
    body: JSON.stringify({
      operationName: null,
      query,
      variables: {},
    }),
  })
    .then((result: Response) => {
      const { errors, data } = result;

      if (errors) {
        return Promise.reject(parseGraphQlError(errors));
      }
      return Promise.resolve(data);
    })
    .catch((error) => {
      try {
        const parsedError = JSON.parse(error?.message || '{}');
        if (parsedError.errors) {
          return Promise.reject(parseGraphQlError(parsedError.errors));
        }
      } catch (parseError) {
        // If parsing fails, return the original error
      }
      return Promise.reject(error);
    });

export default query;

import React, { useMemo, useEffect } from 'react';
import classnames from 'classnames';
import { DateSelection } from 'types';
import {
  DateControls,
  LeftSidebar,
  useLeftSidebarState,
  ProductTour,
} from 'components';
import { Datepicker } from 'composite';
import RumSidebar from './RumSidebar';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ShowSidebarTooltipButton } from 'components';
import rumLabels from './requests/rumLabels';
import useRequest from 'hooks/useRequest';
import RumPerformanceGrid from './RumPerformanceGrid';
import RumPerformanceOverview from './RumPerformanceOverview';
import { RumTab } from './types';
import { RumPerformanceSubtab } from './types';
import RumApplicationPerformanceTab from './RumCoreWebVitals/RumApplicationPerformanceTab';
import { RumEventType } from 'screens/Rum';
import { CursorStateProvider } from 'components';

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
  rumTab: RumTab | RumPerformanceSubtab;
};

const RumPerformance = ({ rumPageState, rumTab }: Props) => {
  const { dependenciesForWriteStateToUrl, rumState, writeStateToUrl } =
    rumPageState;

  const leftSidebarState = useLeftSidebarState('rum');
  const { dateState } = rumState;
  const [date, setDate] = dateState;
  const rumLabelNamesRequest = useRequest(rumLabels);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  useEffect(() => {
    rumLabelNamesRequest.call({ eventType: RumEventType.VIEW });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const setRumTab = (newTab: RumTab) => {
    writeStateToUrl(newTab);
  };

  return (
    <div
      className={classnames({
        rum: true,
        'relative flex w-full flex-row overflow-hidden': true,
      })}
    >
      <LeftSidebar leftSidebarState={leftSidebarState}>
        <RumSidebar
          rumLabelNamesRequest={rumLabelNamesRequest}
          rumState={rumState}
        />
      </LeftSidebar>
      <div className="p-4">
        {leftSidebarState.width === 0 ? (
          <ShowSidebarTooltipButton onClick={leftSidebarState.show} />
        ) : null}
      </div>
      <div className="rum__main  relative flex flex-col overflow-auto p-4">
        <div className="rum__header flex flex-row items-start justify-end">
          <div className="rum__header__right">
            <div className="flex flex-row items-center justify-end">
              <Datepicker onChange={setDate} value={date as DateSelection} />
              <DateControls date={date} setDate={setDate} />
            </div>
          </div>
        </div>
        <h1 className="rum__performance-title">
          Analyze your application performance
        </h1>
        <div className="rum__subtab_div">
          <RumApplicationPerformanceTab />
        </div>

        <div>
          {rumTab === RumTab.performanceMonitoring && (
            <CursorStateProvider>
              <RumPerformanceOverview rumPageState={rumPageState} />
            </CursorStateProvider>
          )}
        </div>
        <div>
          {rumTab === RumPerformanceSubtab.performance ? (
            <CursorStateProvider>
              <RumPerformanceGrid rumPageState={rumPageState} />
            </CursorStateProvider>
          ) : null}
        </div>
        <div></div>
      </div>
      <ProductTour />
    </div>
  );
};

export default RumPerformance;

import React from 'react';
import { Json, RightSidebar, Tab, Tabs, useTabs } from 'components';
import useDebouncedEffect from 'use-debounced-effect';
import useRequest from 'hooks/useRequest';
import {
  RUMEvent,
  RumActionEventData,
  RumEventType,
  RumViewEventData,
} from './types';
import rawEvent from './requests/rawEvent';
import RumActiveLogs from './RumActiveLogs';
import RumActiveTraces from './RumActiveTraces';
import { useRumPageStateContext } from 'context/PageStateProvider';

type Props = {
  close: VoidFunction;
  activeRumEvent: RUMEvent;
  eventType: RumEventType;
};

const RumEventDrawer = ({ activeRumEvent, close, eventType }: Props) => {
  const rumRawEventRequest = useRequest(rawEvent);

  const tabs = useTabs();
  const rumPageState = useRumPageStateContext();
  const { rumState } = rumPageState;
  const { selectedFacetValuesByNameState, dateState } = rumState;
  const [date, setDate] = dateState;

  useDebouncedEffect(
    () => {
      if (!activeRumEvent) {
        return;
      }
      rumRawEventRequest.call({
        eventTimeUnix: activeRumEvent.time,
        eventId:
          eventType === RumEventType.VIEW
            ? (activeRumEvent.data as RumViewEventData)['view.id']
            : (activeRumEvent.data as RumActionEventData)['action.id'],
        eventType,
      });
    },
    {
      timeout: 50,
      ignoreInitialCall: false,
    },
    [activeRumEvent, eventType],
  );

  return (
    <RightSidebar
      className="rum-sidebar flex flex-col"
      close={close}
      title={'Event Attributes'}
      dataTestId="rum-sidebar"
    >
      <div className="trace-sidebar__active-span__body">
        <Tabs className="tabs--underline" tabs={tabs}>
          <Tab label="Info">
            {rumRawEventRequest.result && (
              <Json data={rumRawEventRequest.result} />
            )}
          </Tab>

          <Tab label="Logs">
            <RumActiveLogs
              date={date}
              selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            />
          </Tab>
          <Tab label="Traces">
            <RumActiveTraces
              date={date}
              selectedFacetValuesByNameState={selectedFacetValuesByNameState}
            />
          </Tab>
        </Tabs>
      </div>
    </RightSidebar>
  );
};

export default RumEventDrawer;

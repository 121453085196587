import { AnalyticsChart } from 'components';
import React, {
  MutableRefObject,
  ReactElement,
  useEffect,
  useRef,
} from 'react';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';
import { DashboardPanelType, LogsState } from 'types';

import { useQueryScheduler, useQuerySchedulerEffect } from '../hooks';
import { useLogsAnalyticsChart } from './hooks';
import { useFeatureFlag } from 'configcat-react';

const LogsAnalytics = ({
  queryScheduler,
  logsState,
  tabFetchedRef,
  logsAnalytics,
}: {
  logsState: LogsState;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  tabFetchedRef: MutableRefObject<Record<string, number>>;
  logsAnalytics: ReturnType<typeof useLogsAnalyticsChart>;
}): ReactElement => {
  const {
    analyticsChart,
    chartFormulas,
    chartQueries,
    groupBysForTable,
    logsMetricsQueryState,
    logsAnalyticsOverlayMessage,
    onClickCreateAlertLogs,
    openExportToDashboardModal,
    openSaveMetricModal,
    onViewFullscreen,
  } = logsAnalytics;
  const { formulas, logsChartData, queries } = logsMetricsQueryState;
  const { activeVisualization } = analyticsChart;
  const refQueries = useRef([]);
  const { value: enableSaveMetrics } = useFeatureFlag(
    'enableSaveMetrics',
    false,
  );

  useEffect(() => {
    if (
      ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
        activeVisualization as unknown as DashboardPanelType,
      )
    ) {
      const countOfActiveFormulas = formulas?.filter(
        (formula) => formula.isActive,
      )?.length;
      if (countOfActiveFormulas > 0) {
        logsMetricsQueryState.activateOnlySingleFormula(0);
        logsMetricsQueryState.deactivateAllQueries();
        return;
      }

      const countOfActiveQueries = queries.filter((query) => query.isActive);
      if (countOfActiveQueries.length > 1) {
        logsMetricsQueryState.activateOnlySingleQiery(0);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [activeVisualization]);

  useEffect(() => {
    refQueries.current = queries;
    const activeFormulas = formulas.filter((formula) => formula.isActive);
    const activeQueries = queries.filter((query) => query.isActive);
    const countOfActiveQueriesOrFormulas =
      activeFormulas?.length + activeQueries?.length;

    if (countOfActiveQueriesOrFormulas < 1) {
      // logsMetricsQueryState.activateOnlySingleQiery(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queries, formulas]);

  useQuerySchedulerEffect({
    cb: () => {
      return new Promise((resolve) => {
        resolve();
      });
    },
    logsState,
    queryScheduler,
    sort: undefined,
    tab: 'analytics',
    tabFetchedRef,
  });

  return (
    <div className="logs__analytics">
      <AnalyticsChart
        analyticsChart={analyticsChart}
        chartData={logsChartData}
        chartQueries={chartQueries}
        chartFormulas={chartFormulas}
        defaultActiveChart="Stacked Bar"
        groupBy={groupBysForTable}
        overlayMessage={logsAnalyticsOverlayMessage}
        rightToolbar={{
          enableExportForInstantQueries: true,
          onSaveClick: enableSaveMetrics ? openSaveMetricModal : undefined,
          onExportClick: openExportToDashboardModal,
          onViewFullscreen,
          onCreateAlert: onClickCreateAlertLogs,
        }}
        setDate={logsState.setDate}
        settingOptions={{
          legendType: 'values',
          toolbarChartShowType: 'dropdown',
        }}
      />
    </div>
  );
};

export default LogsAnalytics;

import dayjs from 'dayjs';
import queryRumService from './queryRumService';
import { SelectedFacetValuesByName } from 'types/selectedFacets';
import { buildRumFilter } from './utils';
import { formatNs } from 'utils/timeNs';
import { TimeUnit } from 'types';

type Args = {
  endTimeUnix: number;
  startTimeUnix: number;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  aggregateFunction: string;
};

const rumAggregateInstants = async ({
  endTimeUnix,
  startTimeUnix,
  selectedFacetValuesByName,
  aggregateFunction,
}: Args): Promise<any> => {
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;

  const rawData = await queryRumService<any, 'aggregateTable'>(`
      query {
        aggregateTable(
          eventType: VIEW,
          timestamp: "${endTime.toISOString()}"
          durationSecs: ${durationSecs}
          aggregates: [
            {
              field: "view.largest_contentful_paint"
              aggregation: "${aggregateFunction}",
            },
            {
              field: "view.cumulative_layout_shift"
              aggregation: "${aggregateFunction}",
            },
            {
              field: "view.interaction_to_next_paint"
              aggregation: "${aggregateFunction}",
            },
          ]
          filter: ${buildRumFilter({
            selectedFacetValuesByName,
          })}
      ) {
        aggregates
      }
    }
  `);

  if (!rawData?.aggregateTable || rawData.aggregateTable.length === 0) {
    return { data: {} };
  }

  const formattedAggregates = rawData.aggregateTable.reduce(
    (acc, item) => {
      acc['view.largest_contentful_paint'] =
        `${formatNs(item.aggregates[0], TimeUnit.SECONDS, 2)}s`;
      acc['view.cumulative_layout_shift'] = item.aggregates[1]?.toFixed(2);
      acc['view.interaction_to_next_paint'] =
        `${formatNs(item.aggregates[2], TimeUnit.SECONDS, 2)}s`;
      return acc;
    },
    {} as Record<string, string>,
  );

  return [
    {
      data: formattedAggregates,
    },
  ];
};

export default rumAggregateInstants;

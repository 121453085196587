import { useModalsContext, useToaster } from 'components';
import { useDateState, useRequest, useToggle } from 'hooks';
import { isNil } from 'lodash';
import { useRef, useState } from 'react';
import { Layout } from 'react-grid-layout';
import { useNavigate } from 'react-router-dom';
import { deleteDashboardByUid, mutateGrafanaDashboard } from 'requests';
import {
  DateSelection,
  DashboardProps,
  DashboardPanelProps,
  DashboardPanelTargetsProps,
  DashboardPanelType,
  DashboardReloadPanelsProps,
  DashboardTemplateProps,
} from 'types';

import { MAX_DYNAMIC_REPEATED_ROWS } from '../constants';
import { DashboardUpdateEditedPanelProps } from '../types';
import {
  findChangedDashboardPanels,
  getDefaultDashboardSettings,
  getLargestYAxisValue,
  getReloadPanels,
  getSaveDashboardPanels,
  getRepeatedRowsWithPanels,
  isDragItemPlaceholder,
  transformPanels,
} from '../utils';

const AddPanelPlaceholder: DashboardPanelProps = {
  gridPos: { i: 'a', x: 0, y: 0, w: 6, h: 4, minW: 1, minH: 2 },
  title: 'Add Panel',
  type: DashboardPanelType.PLACEHOLDER,
};

const useDashboardState = () => {
  const { addToast } = useToaster();
  const userActionRef = useRef({
    isDragging: false,
    repeatedRowInitiated: false,
    defaultTimeChanged: false,
  });
  const navigate = useNavigate();
  const [date, setDate] = useDateState();
  const [dashboardDetails, setDashboardDetails] = useState<DashboardProps>({
    ...getDefaultDashboardSettings(),
  });
  const [dashboardMeta, setDashboardMeta] = useState<Record<string, any>>({});
  const [dragItemSize, setDragItemSize] = useState({ w: 3, h: 2 });
  const [selectedPanel, setSelectedPanel] = useState<{
    nestedIndex: string;
    panelIndex: number;
  }>({
    nestedIndex: null,
    panelIndex: null,
  });
  const isRightSidebarOpenToggle = useToggle(false);
  const panelSetupModal = useModalsContext();
  const [panels, setPanels] = useState<DashboardPanelProps[]>([]);
  const [reloadPanels, setReloadPanels] = useState<DashboardReloadPanelsProps>(
    {},
  );
  const grafanaDashboardMutateRequest = useRequest(mutateGrafanaDashboard);
  const dashboardDeleteByUidRequest = useRequest(deleteDashboardByUid);

  const initialDashboardSetup = (dashboard: any) => {
    const { panels } = dashboard;
    setDashboardDetails((prevState) => {
      return { ...prevState, ...dashboard };
    });

    const transformedPanels = transformPanels(panels);
    setReloadPanels(getReloadPanels(transformedPanels, {}));

    setPanels(transformedPanels);
  };

  const addPanel = (panelData: DashboardPanelProps, nestedIndex: string) => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      const newReloadPanels: DashboardReloadPanelsProps = {};
      if (nestedIndex) {
        const nestedIndexNumber = Number(nestedIndex);
        newPanels[nestedIndexNumber].panels.push(panelData);
        const panelId = newPanels[nestedIndexNumber].panels.length - 1;
        newReloadPanels[nestedIndex][`${panelId}`] = true;
      } else {
        newPanels.push(panelData);
        const panelId = newPanels.length - 1;
        newReloadPanels[`${panelId}`] = true;
      }

      const placeholderIndex = prevPanels.findIndex(
        (panel) => panel.type === 'placeholder',
      );

      if (placeholderIndex !== -1) {
        newPanels.splice(placeholderIndex, 1);
      }

      if (placeholderIndex === -1) {
        const newY = getLargestYAxisValue(newPanels);
        AddPanelPlaceholder.gridPos.y = newY + 4;
        newPanels.push(AddPanelPlaceholder);
      }

      setReloadPanels(newReloadPanels);
      return newPanels;
    });
    isRightSidebarOpenToggle.off();
  };

  const updateMetricPanel = (
    panelIndex: number,
    targets: DashboardPanelTargetsProps[],
  ) => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      newPanels[panelIndex].targets = targets;
      return newPanels;
    });
  };

  const deletePanel = (panelIndex: number) => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      newPanels.splice(panelIndex, 1);
      if (newPanels.length === 0) {
        newPanels.push(AddPanelPlaceholder);
      }
      newPanels[0].isEdited = true;
      return newPanels;
    });
  };

  const updateLayoutChanges = (layouts: Layout[], nestedIndex: string) => {
    if (isDragItemPlaceholder(layouts)) {
      return;
    }

    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];

      const changedPanel = findChangedDashboardPanels(
        newPanels.map((panel) => panel.gridPos),
        layouts,
      );

      if (changedPanel.length === 0) {
        return prevPanels;
      }

      let layoutPanels = newPanels;
      if (nestedIndex) {
        layoutPanels = newPanels[Number(nestedIndex)].panels;
      }
      layouts.forEach((layout) => {
        const panelIndex = Number(layout.i);
        const pl = layoutPanels[panelIndex];
        const newGridPos = {
          ...pl.gridPos,
          ...{ x: layout.x, y: layout.y, w: layout.w, h: layout.h },
        };
        layoutPanels[panelIndex].gridPos = newGridPos;
        if (
          !layoutPanels[panelIndex].isEdited &&
          layoutPanels[panelIndex].type !== DashboardPanelType.PLACEHOLDER
        ) {
          const isPanelChanged = changedPanel.find(
            (panel) => panel.i === layout.i,
          );
          layoutPanels[panelIndex].isEdited = Boolean(isPanelChanged);
        }
      });

      if (nestedIndex) {
        newPanels[Number(nestedIndex)].panels = layoutPanels;
      }
      return newPanels;
    });
  };

  const updateSelectedPanel = (panelIndex: number, nestedIndex: string) => {
    if (
      panelIndex === selectedPanel.panelIndex &&
      nestedIndex === selectedPanel.nestedIndex
    ) {
      setSelectedPanel({ nestedIndex: null, panelIndex: null });
      return;
    }
    if (nestedIndex) {
      setSelectedPanel({ nestedIndex, panelIndex });
    } else {
      setSelectedPanel({ nestedIndex: null, panelIndex });
    }
  };

  const removePlaceholder = () => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      const placeholderIndex = newPanels.findIndex(
        (panel) => panel.type === 'placeholder',
      );
      if (placeholderIndex !== -1) {
        const newGrid = { ...newPanels[placeholderIndex].gridPos };
        newGrid.w = 0;
        newGrid.h = 0;
        newPanels[placeholderIndex].gridPos = newGrid;
      }

      return newPanels;
    });
  };

  const addPlaceholder = () => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      const placeholderIndex = newPanels.findIndex(
        (panel) => panel.type === 'placeholder',
      );
      const newY = getLargestYAxisValue(newPanels);
      const newAddPanelPlaceholder = { ...AddPanelPlaceholder };
      newAddPanelPlaceholder.gridPos = {
        ...AddPanelPlaceholder.gridPos,
        y: newY + 4,
      };

      if (placeholderIndex !== -1) {
        newPanels[placeholderIndex] = newAddPanelPlaceholder;
      } else {
        newPanels.push(newAddPanelPlaceholder);
      }

      return newPanels;
    });
  };

  const updatePanel = ({
    panelIndex,
    nestedIndex,
    propertyKey,
    value,
  }: {
    panelIndex: number;
    nestedIndex: number;
    propertyKey: string;
    value: any;
  }) => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      if (nestedIndex) {
        newPanels[nestedIndex].panels[panelIndex][propertyKey] = value;
      } else {
        newPanels[panelIndex][propertyKey] = value;
      }
      return newPanels;
    });
  };

  const updateEditedPanel = ({
    panel,
    panelIndex,
    nestedIndex,
  }: DashboardUpdateEditedPanelProps) => {
    setPanels((prevPanels) => {
      const newPanels = [...prevPanels];
      if (isNil(panelIndex)) {
        newPanels.push(panel);
        updateReloadForEditPanel(panelIndex, nestedIndex, newPanels);
        return newPanels;
      }

      if (nestedIndex) {
        panel.gridPos.i = `${nestedIndex}-${panelIndex}`;
        newPanels[Number(nestedIndex)].panels[panelIndex] = panel;
      } else {
        panel.gridPos.i = `${panelIndex}`;
        newPanels[panelIndex] = panel;
      }
      updateReloadForEditPanel(panelIndex, nestedIndex, newPanels);
      return newPanels;
    });
  };

  const updateReloadForEditPanel = (
    panelIndex: number,
    nestedIndex: string,
    newPanels: DashboardPanelProps[],
  ) => {
    setReloadPanels((prev) => {
      const newReloadPanels = { ...prev };
      if (!panelIndex) {
        const panelId = newPanels.length - 1;
        newReloadPanels[`${panelId}`] = true;
        return newReloadPanels;
      }

      if (nestedIndex) {
        newReloadPanels[`${nestedIndex}-${panelIndex}`] = true;
      } else {
        newReloadPanels[`${panelIndex}`] = true;
      }
      return newReloadPanels;
    });
  };

  const onUpdateDashboards = () => {
    const newPanels = getSaveDashboardPanels(panels);
    const jsonModel = { ...dashboardDetails, panels: newPanels };
    if (jsonModel.isEdited) {
      delete jsonModel.isEdited;
    }

    grafanaDashboardMutateRequest
      .call({
        jsonModel,
        message: `${new Date().toISOString()}`,
        folderUid: dashboardDetails?.folderUid,
      })
      .then((res: { uid: string; version: number }) => {
        addToast({ status: 'success', text: 'Dasboard saved successfully' });
        setDashboardDetails((prevState) => ({
          ...prevState,
          uid: res.uid,
          version: res.version,
          isEdited: false,
        }));
        setDashboardMeta((prevState) => ({
          ...prevState,
          folderUid: dashboardDetails?.folderUid,
        }));

        setPanels((prevPanels) => {
          const newPanels = [...prevPanels];
          newPanels.forEach((panel) => {
            panel.isEdited = false;
          });
          return newPanels;
        });
      });
  };

  const onConfirmDashboardDelete = () => {
    dashboardDeleteByUidRequest.call(dashboardDetails.uid).then(() => {
      addToast({ status: 'success', text: 'Dasboard deleted successfully' });
      navigate('/dashboards/lists');
    });
  };

  const handleTemplateRepeatedRows = (
    template: DashboardTemplateProps,
    values: string[],
  ) => {
    const repeatedPanels = getRepeatedRowsWithPanels({
      panels,
      template,
      values: values.slice(0, MAX_DYNAMIC_REPEATED_ROWS),
    });
    if (repeatedPanels.length > 0) {
      setPanels(repeatedPanels);
      setReloadPanels((prev) => {
        const newReloadPanels = { ...prev };
        repeatedPanels.forEach((panel, index) => {
          if (panel.repeat === template.name && panel.repeatValue) {
            newReloadPanels[`${index}`] = true;
          }
        });
        return newReloadPanels;
      });
    }
  };

  const onDateChange = (date: DateSelection, isInit?: boolean) => {
    if (!isInit) {
      userActionRef.current.defaultTimeChanged = true;
    }
    setReloadPanels(getReloadPanels(panels, {}));
    setDate(date);
  };

  return {
    addPanel,
    addPlaceholder,
    date,
    dashboardDeleteByUidRequest,
    dashboardDetails,
    dashboardMeta,
    deletePanel,
    dragItemSize,
    grafanaDashboardMutateRequest,
    initialDashboardSetup,
    isRightSidebarOpenToggle,
    handleTemplateRepeatedRows,
    onDateChange,
    panels,
    panelSetupModal,
    removePlaceholder,
    reloadPanels,
    onConfirmDashboardDelete,
    onUpdateDashboards,
    selectedPanel,
    setDashboardDetails,
    setDashboardMeta,
    setDragItemSize,
    setPanels,
    setReloadPanels,
    updateEditedPanel,
    updateLayoutChanges,
    updateMetricPanel,
    updatePanel,
    updateSelectedPanel,
    userActionRef,
  };
};

export default useDashboardState;

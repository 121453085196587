import { AutocompleteV2 } from 'components';
import React, { useMemo } from 'react';
import { IoIosWarning } from 'react-icons/io';

const getOptionVal = (option: string) => {
  switch (option) {
    case 'None':
      return null;
    default:
      return option;
  }
};

type Props = {
  customerFilter: { key: string; value: string };
  filterLabel: string;
  valueOptions: string[];
  isLoading?: boolean;
  setCustomerFilter: ({ key, value }: { key: string; value: string }) => void;
  error: {
    getGroupLabel?: { message: string };
  };
};

const CustomerFilter = ({
  customerFilter,
  filterLabel,
  isLoading,
  valueOptions,
  setCustomerFilter,
  error,
}: Props) => {
  const optionsWithAllAndNone = useMemo(() => {
    return ['All', 'None', ...valueOptions];
  }, [valueOptions]);

  const optionsWithLabelAndValue = useMemo(() => {
    return optionsWithAllAndNone.map((option) => ({
      label: option,
      value: getOptionVal(option),
    }));
  }, [optionsWithAllAndNone]);

  return (
    <div className="customer-filter-selector ml-2.5 mb-2.5 h-auto flex-col items-stretch">
      <div className="button-group__item button-group__item--label min-h-6	">
        {error?.getGroupLabel ? (
          <div className="flex gap-[4px] w-full justify-start pr-[14px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={16}
            />
            <div className="text-red-500">{error?.getGroupLabel?.message}</div>
          </div>
        ) : (
          <>{filterLabel}</>
        )}
      </div>
      <AutocompleteV2
        className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value ms-2"
        isLoading={isLoading}
        onChange={(value) => {
          setCustomerFilter({ key: filterLabel, value: value as string });
        }}
        options={optionsWithLabelAndValue}
        value={customerFilter?.value}
      />
    </div>
  );
};

export default CustomerFilter;

import {
  CheckboxWithLabel,
  PopoverPosition,
  PopoverTriggerV2,
  SelectV2,
  TimeseriesExplorer,
  TooltipTrigger,
  useModalsContext,
} from 'components';
import { useForm } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { BsArrowsFullscreen } from 'react-icons/bs';
import { IoSettingsOutline } from 'react-icons/io5';
import { Chart, ChartGridItemType, DateSelection } from 'types';
import ChartGridItemHeaderTopK from './ChartGridItemHeaderTopK';
import { Compare } from './types';
import useChartGridItemData from './useChartGridItemData';
import { IoIosWarning } from 'react-icons/io';

const compareOptions = (compare: Compare) => [
  { label: `${compare ? 'Remove Compare' : 'Compare'}`, value: null },
  ...Object.values(Compare).map((compare) => ({
    label: `1 ${compare} before`,
    value: compare,
  })),
];

type Props = {
  chart: Chart;
  chartGridItem: ChartGridItemType;
  chartGridItemData: ReturnType<typeof useChartGridItemData>;
  date: DateSelection;
  form: ReturnType<typeof useForm>;
  width: number;
};

const ChartGridItemHeader = ({
  chart,
  chartGridItem,
  chartGridItemData,
  date,
  form,
  width,
}: Props) => {
  const { charts } = chartGridItem;
  const { chartType, libraryType, renderComponent, unit } = chart;
  const { queryRangeRequest, error } = chartGridItemData;

  const modal = useModalsContext();
  const { propsByKey, values } = form;

  const chartOptions = charts.map((chart) => ({
    label: chart.label,
    value: chart.key,
  }));

  const onExploreClick = async (width: number) => {
    if (chart.traceQueries) {
      modal.push(
        <TimeseriesExplorer
          activeQueries={chart.traceQueries}
          activeChartType={chartType === 'bar' ? 'Stacked Bar' : 'Line'}
          chartData={null}
          date={date}
          queryType="trace"
          onClose={modal.pop}
          unit={unit}
        />,
      );
      return;
    }
    if (chart.rumQueries) {
      modal.push(
        <TimeseriesExplorer
          activeQueries={chart.rumQueries}
          activeChartType={chartType === 'bar' ? 'Stacked Bar' : 'Line'}
          chartData={null}
          date={date}
          queryType="rum-performance"
          onClose={modal.pop}
          unit={unit}
        />,
      );
      return;
    }

    const chartQuery = await chart.query({
      chart,
      date,
      width,
      parsedPromql: true,
    });

    const { promqls, stepInMs, legendFormats } = chartQuery;
    const activeQueries = promqls.map((promql: string, idx: number) => {
      return {
        promql,
        steps: stepInMs / 1000,
        legendFormat: (legendFormats && legendFormats[idx]) || '',
      };
    });

    modal.push(
      <TimeseriesExplorer
        activeChartType={chartType === 'bar' ? 'Stacked Bar' : 'Line'}
        activeQueries={activeQueries}
        chartData={queryRangeRequest.result || { data: [], series: [] }}
        date={date}
        onClose={modal.pop}
        renderComponent={renderComponent}
        queryType="promql"
        unit={unit}
      />,
    );
  };
  const totalCount = useMemo(() => {
    if (!chart?.totalKey) {
      return null;
    }

    const total = chartGridItemData?.data?.reduce((acc, item) => {
      const curVal = item[chart?.totalKey];
      return acc + curVal;
    }, 0);
    return total;
  }, [chart?.totalKey, chartGridItemData?.data]);

  const keyProps = propsByKey('key');
  const onKeyChange = (nextParam: string) => {
    keyProps.onChange(nextParam);
    if (chartGridItem.onKeyChange) {
      chartGridItem.onKeyChange(nextParam);
    }
  };

  useEffect(() => {
    if (chart?.enableLogScaleByDefault) {
      form.setValue('isLogScaleEnabled', true);
    } else {
      form.setValue('isLogScaleEnabled', false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [values.key]);

  return (
    <div className="chart-grid__item__header">
      <div className="chart-grid__item__header__left">
        {chartOptions.length > 1 ? (
          <SelectV2.Select
            className="select--thin"
            {...keyProps}
            onChange={onKeyChange}
            options={chartOptions}
            dataTestId={chart.key}
          />
        ) : chart?.totalKey ? (
          <div className="flex px-3">
            {`Total ${chart?.label}:`}
            &nbsp;
            <span
              className="text--weight-medium"
              data-testid="total-request-count"
            >
              {typeof chart?.renderTotalCount === 'function'
                ? chart.renderTotalCount(totalCount)
                : totalCount}{' '}
            </span>
          </div>
        ) : (
          <div className="chart-grid__item__header__left__placeholder">
            {chart?.label}
          </div>
        )}
      </div>
      <div className="chart-grid__item__header__right">
        {!!error?.getCompareRange && (
          <div className="flex gap-[4px] mr-[6px]">
            <IoIosWarning
              className="overlay-message__icon-and-message__icon"
              size={20}
            />
            <div className="text-red-500">{error?.getCompareRange}</div>
          </div>
        )}

        {chart?.disabledCompare || libraryType ? null : (
          <SelectV2.Select
            className="select--thin select--naked"
            options={compareOptions(values.compare)}
            placeholder="Compare"
            {...propsByKey('compare')}
          />
        )}
        {chart?.disableExplore ? null : (
          <TooltipTrigger tooltip="Explore Metric">
            <div
              className="chart-grid__item__header__right__explorer"
              onClick={() => onExploreClick(width)}
            >
              <BsArrowsFullscreen size={12} />
            </div>
          </TooltipTrigger>
        )}
        {chart.additionalButtons ? (
          <>
            {chart.additionalButtons.map((button) => (
              <TooltipTrigger key={button.key} tooltip={button.tooltip}>
                <div
                  className="chart-grid__item__header__right__explorer"
                  onClick={button.onClick}
                >
                  {button.icon}
                </div>
              </TooltipTrigger>
            ))}
          </>
        ) : null}
        <PopoverTriggerV2
          className="chart-grid__item__header__right__settings"
          popover={() => (
            <div>
              {chart?.disableLogScale || values.isScaledToPercentage ? null : (
                <>
                  <CheckboxWithLabel
                    label="Show Log Scale"
                    {...(propsByKey('isLogScaleEnabled') as {
                      onChange: (value: any) => void;
                      value: boolean;
                    })}
                    dataTestId="show-log-scale"
                  />
                </>
              )}
              {!chart?.enableAnomaly ? null : (
                <>
                  <CheckboxWithLabel
                    label="Show Anomaly"
                    {...(propsByKey('isAnomalyEnabled') as {
                      onChange: (value: any) => void;
                      value: boolean;
                    })}
                    dataTestId="show-anomaly"
                  />
                </>
              )}
              {!chart?.enablePercentageChart ||
              values.isLogScaleEnabled ? null : (
                <>
                  <CheckboxWithLabel
                    label="Scaled to 0-100%"
                    {...(propsByKey('isScaledToPercentage') as {
                      onChange: (value: any) => void;
                      value: boolean;
                    })}
                    dataTestId="scale"
                  />
                </>
              )}
              {!chart?.enableSpanOverlay ? null : (
                <>
                  <CheckboxWithLabel
                    label="Outlier Spans"
                    {...(propsByKey('isSpanOverlayEnabled') as {
                      onChange: (value: any) => void;
                      value: boolean;
                    })}
                    dataTestId="outlier-spans"
                  />
                </>
              )}
              <ChartGridItemHeaderTopK
                chart={chart}
                chartGridItemData={chartGridItemData}
                form={form}
              />
            </div>
          )}
          position={PopoverPosition.BOTTOM_RIGHT}
        >
          <IoSettingsOutline size={16} />
        </PopoverTriggerV2>
      </div>
    </div>
  );
};

export default ChartGridItemHeader;

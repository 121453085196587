import { useRequest } from 'hooks';
import { useEffect, useMemo } from 'react';
import {
  getLogMetricsTimeSeriesLogQLMultiple,
  promqlQuery,
  promqlQueryRange,
} from 'requests';
import {
  DashboardPanelType,
  DateSelection,
  MetricsTransformSeriesProps,
  RequestResult,
} from 'types';
import { chartColors } from 'utils';

import { DashboardTemplateValueProps } from '../types';
import {
  formatDashboardLegend,
  getActivePromqlQuery,
  getLogqlWithQuery,
  getPanelWidthHeight,
  getReplacedInterval,
  getReplacedTimeFromWithDate,
  getTemplateValueWithRepeated,
  isPanelForecastTimeseries,
  mapTimeseriesDrawStyle,
  transformFormulaExpression,
  transformPromql,
} from '../utils';
import useDashboardState from './useDashboardState';

const useDashboardDataLoader = ({
  baseWidth,
  dashboardState,
  isInView,
  nestedIndex,
  panelIndex,
  templateValues,
  type,
}: {
  baseWidth?: number;
  dashboardState: ReturnType<typeof useDashboardState>;
  isInView?: boolean;
  nestedIndex: string;
  panelIndex: number;
  templateValues: DashboardTemplateValueProps;
  type: string;
}): RequestResult<any, any> => {
  const promqlQueryRequest = useRequest(promqlQuery, true, true);
  const promqlQueryRangeRequest = useRequest(promqlQueryRange, true, true);
  const getLogMetricsTimeSeriesLogQLRequest = useRequest(
    getLogMetricsTimeSeriesLogQLMultiple,
    true,
    true,
  );

  const { date, panels, reloadPanels, setReloadPanels, userActionRef } =
    dashboardState;
  const nestedIndexNum = Number(nestedIndex);
  let panel = panels[panelIndex];
  let panelKey = `${panelIndex}`;

  if (
    nestedIndex !== undefined &&
    nestedIndex !== null &&
    nestedIndexNum >= 0
  ) {
    panel = panels[nestedIndexNum].panels[panelIndex];
    panelKey = `${nestedIndexNum}-${panelIndex}`;
  }

  const { width: panelWidth } = useMemo(
    () => getPanelWidthHeight(panel.gridPos, baseWidth),
    [baseWidth, panel?.gridPos],
  );

  const defaultChartTypes = useMemo(
    () => mapTimeseriesDrawStyle(panel?.fieldConfig?.defaults?.custom),
    [panel?.fieldConfig],
  );

  if (!panel) return {};

  const { datasource, fieldConfig, targets } = panel;
  const isLoki = datasource && datasource.type === 'loki';
  const templateValuesWithRepeated = getTemplateValueWithRepeated(
    templateValues,
    panel,
  );
  let isRange = targets.some((target) => target.range && !target.hide);
  if (
    isRange &&
    type !== 'unflattened' &&
    type !== DashboardPanelType.TIMESERIES
  ) {
    isRange = false;
  }

  if (type === DashboardPanelType.TIMESERIES) {
    isRange = true;
  }

  const callInstantPrometheus = (panelDate: DateSelection) => {
    const promqlQueries = getActivePromqlQuery(panel.targets).map((target) =>
      transformPromql({
        date: panelDate,
        promql: target.expr,
        templateValues: templateValuesWithRepeated,
        width: panelWidth,
        chartType: defaultChartTypes[0],
      }),
    );

    promqlQueryRequest
      .call({
        promqlQueries,
        responseFormat: type === 'stat' ? undefined : type,
      })
      .catch(() => {});
  };

  const callRangePrometheus = (
    panelDate: DateSelection & { allowFutureTime?: boolean },
  ) => {
    const promqlQueries = getActivePromqlQuery(panel.targets).map((target) => {
      const prop = {
        date: panelDate,
        templateValues: templateValuesWithRepeated,
        width: panelWidth,
      };
      if (target.expression) {
        return transformFormulaExpression({
          ...prop,
          expression: target.expression,
          targets,
          chartType: defaultChartTypes[0],
        });
      }

      return transformPromql({
        ...prop,
        chartType: defaultChartTypes[0],
        promql: target.expr,
      });
    });

    const colorStartIdx = Math.floor(Math.random() * chartColors.length);
    const isForecast = isPanelForecastTimeseries(
      fieldConfig?.defaults.mappings,
    );
    const intervals = [];
    if (userActionRef.current.defaultTimeChanged === false) {
      intervals.push(
        ...getReplacedInterval(targets, templateValuesWithRepeated),
      );
    }
    promqlQueryRangeRequest.call({
      allowFutureTime: panelDate.allowFutureTime,
      date: panelDate,
      metricNames: [],
      promqlQueries,
      seriesFormatter: ({ idx, ...rest }: MetricsTransformSeriesProps) =>
        formatDashboardLegend({
          seriesIdx: idx,
          targets,
          colorStartIdx,
          ...rest,
        }),
      type: isForecast ? 'unflattened-timeseries' : type,
      steps: intervals,
      chartType: defaultChartTypes[0],
    });
  };

  const callLoki = (panelDate: DateSelection) => {
    const transformedLogQls = getLogqlWithQuery({
      panel,
      templateValuesWithRepeated,
      panelDate,
      defaultChartTypes,
    });
    getLogMetricsTimeSeriesLogQLRequest.call({
      date: panelDate,
      format: type,
      flatten: true,
      instant: type !== DashboardPanelType.TIMESERIES,
      logQlQueries: transformedLogQls.logQlQueries,
      logqlWithMeta: transformedLogQls.logqlWithMeta,
      mainTransformer: transformedLogQls.mainTransformer,
      width: panelWidth,
    });
  };

  const loadData = () => {
    const panelDate = getReplacedTimeFromWithDate({
      date,
      panel,
      templateValues: templateValuesWithRepeated,
    });
    if (isLoki) {
      callLoki(panelDate);
      return;
    }

    if (!isLoki && isRange) {
      callRangePrometheus(panelDate);
      return;
    }

    if (!isLoki && !isRange) {
      callInstantPrometheus(panelDate);
    }
  };

  const isDataLoaded = () => {
    if (isLoki) {
      return Boolean(getLogMetricsTimeSeriesLogQLRequest.result);
    }

    if (!isLoki && isRange) {
      return Boolean(promqlQueryRangeRequest.result);
    }

    if (!isLoki && !isRange) {
      return Boolean(promqlQueryRequest.result);
    }

    return false;
  };

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (isDataLoaded()) {
      setReloadPanels((prevReloadPanels) => ({
        ...prevReloadPanels,
        [panelKey]: false,
      }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    getLogMetricsTimeSeriesLogQLRequest.result,
    promqlQueryRangeRequest.result,
    promqlQueryRequest.result,
  ]);

  // eslint-disable-next-line react-hooks/rules-of-hooks
  useEffect(() => {
    if (reloadPanels[panelKey] && isInView) {
      loadData();
    }
    // eslint-disable-next-line react-hooks/rules-of-hooks, react-hooks/exhaustive-deps
  }, [isInView, panel.targets, templateValues]);

  // here they are returned
  if (isLoki) {
    return getLogMetricsTimeSeriesLogQLRequest;
  }

  if (isRange) {
    return promqlQueryRangeRequest;
  }

  if (!isRange) {
    return promqlQueryRequest;
  }
};

export default useDashboardDataLoader;

import {
  AutocompleteV2,
  ChipWithLabel,
  FacetPicker,
  FacetWithIcon,
  Icon,
  LeftSidebarSectionHeader,
  Loader,
  TooltipTrigger,
  useModalsContext,
} from 'components';
import { SearchInput } from 'components/FacetPicker/FacetPickerValues';
import {
  colorsByLogLevel,
  CoreLabels,
  CoreLabelsBitmap,
  CoreLevelLabel,
  CoreSourceLabel,
  CloudLabelsBitmap,
  delimiter,
  KubernetesLabelsBitmap,
} from 'kfuse-constants';
import {
  FilterType,
  Operator,
  useLogsState,
  useRequest,
  useSubscriptionRequest,
  useSimpleSearch,
} from 'hooks';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { RiExternalLinkLine as ExternalLinkIcon } from 'react-icons/ri';
import {
  getFacetDetail,
  getLabelNames,
  getLogFacetValuesCounts,
  getLabelValuesSubscription,
  getLogsFavoriteFacetGroups,
} from 'requests';
import {
  DashboardPanelType,
  FacetBase,
  FavoriteFacet,
  SelectedFacetRange,
  SelectedFacetValuesByName,
} from 'types';
import {
  getFacetKey,
  getIsComponentLabelStrict,
  getIsLogRangeFacet,
  getRollupByVisualization,
  parseFacetKey,
} from 'utils';
import { useQueryScheduler } from './hooks';
import LogsFacetGroup from './LogsFacetGroup';
import helpText from '../../helpText.js';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { useLogsAnalyticsChart } from './LogsAnalytics/hooks';
import { LogsFacetExplorerEditFavorite } from 'screens/LogsFacetExplorer';
import classNames from 'classnames';
import { IoIosWarning } from 'react-icons/io';

const getSelectedFacetValuesByName = (selectedFacetValues: {
  [key: string]: number;
}): SelectedFacetValuesByName => {
  const result: SelectedFacetValuesByName = {};
  Object.keys(selectedFacetValues).forEach((facetValueCompositeKey: string) => {
    const [component, name, type, displayName, value] =
      facetValueCompositeKey.split(delimiter);
    const facetKey = getFacetKey({
      component,
      name,
      type,
      displayName,
    });

    if (!result[facetKey]) {
      result[facetKey] = {};
    }

    result[facetKey][value] = selectedFacetValues[facetValueCompositeKey];
  });

  return result;
};

const groupLabels = (getlabelNamesResult: string[]) => {
  const additionalLabels = [];
  const cloudLabels = [];
  const kubernetesLabels = [];

  getlabelNamesResult.sort().forEach((labelName) => {
    if (CloudLabelsBitmap[labelName]) {
      cloudLabels.push(CloudLabelsBitmap[labelName]);
    } else if (KubernetesLabelsBitmap[labelName]) {
      kubernetesLabels.push(KubernetesLabelsBitmap[labelName]);
    } else if (!CoreLabelsBitmap[labelName]) {
      additionalLabels.push({
        component: 'Additional',
        name: labelName,
        type: 'string',
      });
    }
  });

  return {
    additionalLabels,
    cloudLabels,
    kubernetesLabels,
  };
};

const LogsSidebar = ({
  getLogStackedBarCountsUsingMetricsRequest,
  isAnalyticsView,
  labelsState,
  logsState,
  queryScheduler,
  refreshFacetGroups,
  setRefreshFacetGroups,
  logsAnalytics,
}: {
  getLogStackedBarCountsUsingMetricsRequest: ReturnType<
    typeof useSubscriptionRequest
  >;
  isAnalyticsView?: boolean;
  labelsState: ReturnType<typeof useState<LabelsProps>>;
  logsState: ReturnType<typeof useLogsState>;
  refreshFacetGroups?: string;
  setRefreshFacetGroups: (component: string) => void;
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  logsAnalytics: ReturnType<typeof useLogsAnalyticsChart>;
}): ReactElement => {
  const modal = useModalsContext();
  const navigate = useNavigate();
  const [params] = useSearchParams();

  const getLabelNamesRequest = useRequest(getLabelNames, true, true);

  const [error, setError] = useState({
    getLabelNames: null,
    getFavoriteFacetGroup: null,
  });

  const logsFavouriteFacetGroupsRequest = useRequest(
    getLogsFavoriteFacetGroups,
    true,
    true,
  );
  const { chartQueries } = logsAnalytics;

  const {
    changeFacetRange,
    date,
    filterOnlyFacetValueFromLogEventDetail,
    filterOrExcludeByFingerprint,
    resetFacet,
    resetFacetRange,
    selectedFacetRanges,
    selectedFacetValues,
    selectedFacetValuesByNameState,
    toggleKeyExists,
  } = logsState;
  const { logsMetricsQueryState } = logsAnalytics;
  const { queries, setSyncSelectedQuery, syncSelectedQuery } =
    logsMetricsQueryState;

  const components = logsFavouriteFacetGroupsRequest.result || [];
  const sourcesSimpleSearch = useSimpleSearch({
    getItemString: (item: string) => item,
    items: components,
  });

  useEffect(() => {
    if (queryScheduler.secondQueryCompletedAt) {
      logsFavouriteFacetGroupsRequest
        .call({})
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getFavoriteFacetGroup: null,
            }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getFavoriteFacetGroup: {
              message: 'Failed to fetch favorite facet group',
            },
          }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryScheduler.secondQueryCompletedAt]);

  useEffect(() => {
    if (queryScheduler.secondQueryCompletedAt) {
      getLabelNamesRequest
        .call({
          logsState,
        })
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({ ...prevError, getLabelNames: null }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getLabelNames: { message: 'Failed to fetch label names' },
          }));
        });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryScheduler.secondQueryCompletedAt]);

  const { additionalLabels, cloudLabels, kubernetesLabels } = useMemo(
    () => groupLabels(getLabelNamesRequest.result || []),
    [getLabelNamesRequest.result],
  );

  const [_, setLabels] = labelsState;

  useEffect(() => {
    setLabels({
      additional: additionalLabels,
      cloud: cloudLabels,
      core: CoreLabels,
      kubernetes: kubernetesLabels,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [additionalLabels, CoreLabels, cloudLabels, kubernetesLabels]);

  useEffect(() => {
    if (!refreshFacetGroups) return;
    const favoriteGroups = logsFavouriteFacetGroupsRequest.result as string[];
    const isGroupFavorite = favoriteGroups?.includes(refreshFacetGroups);

    if (!isGroupFavorite) {
      logsFavouriteFacetGroupsRequest
        .call({})
        .then((nextResult) => {
          if (nextResult) {
            setError((prevError) => ({
              ...prevError,
              getFavoriteFacetGroup: null,
            }));
          }
        })
        .catch(() => {
          setError((prevError) => ({
            ...prevError,
            getFavoriteFacetGroup: {
              message: 'Failed to fetch favorite facet group',
            },
          }));
        });
      setRefreshFacetGroups('');
      return;
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFacetGroups]);

  const handlersByName = (facet: FavoriteFacet) => {
    const { component, name, type, displayName } = facet;
    const facetKey = getFacetKey(facet);
    const isRangeFacet = getIsLogRangeFacet(type);
    return {
      clearFacet: () => {
        if (isRangeFacet) {
          resetFacetRange(facet);
        } else {
          resetFacet({ component, name, type, displayName });
        }
      },
      changeFacetRange: (range: SelectedFacetRange) => {
        changeFacetRange({ facetKey, range });
      },
      excludeFacetValue: (value: string) => {
        filterOnlyFacetValueFromLogEventDetail({
          displayName: facet.displayName,
          exclude: true,
          name,
          source: component,
          type,
          value,
        });
      },
      selectOnlyFacetValue: (value: string) => {
        filterOnlyFacetValueFromLogEventDetail({
          displayName: facet.displayName,
          name,
          source: component,
          type,
          value,
        });
      },
      toggleFacetValue: (value: string, values: any[]) => {
        selectedFacetValuesByNameState.toggleFacetPickerValueCheckbox({
          facetName: facetKey,
          facetValueToToggle: value,
          allFacetValues: values,
        });
      },
      toggleKeyExists: getIsComponentLabelStrict(component)
        ? null
        : () => {
            toggleKeyExists({ component, name, type, displayName });
          },
    };
  };

  const requestByFacet = (facet: FacetBase) => {
    if (getIsComponentLabelStrict(facet.component)) {
      return {
        requestSubscription: getLabelValuesSubscription({ facet, logsState }),
      };
    }

    if (getIsLogRangeFacet(facet.type)) {
      return {
        request: () =>
          getFacetDetail({ facet, logsState }).then((facetDetail) => ({
            min: facetDetail.minValue,
            max: facetDetail.maxValue,
            found: facetDetail.found,
          })),
      };
    }

    return {
      request: () => getLogFacetValuesCounts({ facet, logsState }),
    };
  };

  const requestByLogLevelFacet = (facet: FacetBase) => {
    if (!queryScheduler.secondQueryCompletedAt) {
      return {
        request: () =>
          new Promise((resolve) => {
            resolve([]);
          }),
      };
    }

    return {
      request: () =>
        new Promise((resolve) => {
          const callId = queryScheduler.callIdRef.current;
          const countByLevel: Record<string, number> = {};
          (getLogStackedBarCountsUsingMetricsRequest.result || []).forEach(
            (bucket) => {
              const { count, facetValue } = bucket;
              if (!countByLevel[facetValue]) {
                countByLevel[facetValue] = 0;
              }

              countByLevel[facetValue] += count;
            },
          );

          if (callId === queryScheduler.callIdRef.current) {
            queryScheduler.setEndTime();
          }
          resolve(
            Object.keys(countByLevel).map((level) => ({
              count: countByLevel[level],
              value: level,
            })),
          );
        }),
    };
  };

  const renderNameHandler =
    (facet: FavoriteFacet) =>
    (name: string, nameRef: React.MutableRefObject<HTMLElement>) => {
      return (
        <FacetWithIcon
          facet={facet}
          nameRef={nameRef}
          renderedName={facet.displayName}
        />
      );
    };

  const renderLabelPlaceholderText = (facetKey: string) => {
    const { name } = parseFacetKey(facetKey);
    return `No label values for ${name}`;
  };

  const renderPlaceholderText = (facetKey: string) => {
    const { name } = parseFacetKey(facetKey);
    return `No facet values for ${name}`;
  };

  const selectedFacetValuesByName = useMemo(
    () => getSelectedFacetValuesByName(selectedFacetValues),
    [selectedFacetValues],
  );

  const { keyExists } = logsState;

  const renderGroup = ({ facets, group }) => {
    return (
      <LogsFacetGroup
        component={group}
        key={group}
        getActions={getActions}
        handlersByName={handlersByName}
        hardcodedFacets={facets}
        logsState={logsState}
        requestByFacet={requestByFacet}
        renderPlaceholderText={renderLabelPlaceholderText}
        queryScheduler={queryScheduler}
        renderNameHandler={renderNameHandler}
        selectedFacetRanges={selectedFacetRanges}
        selectedFacetValuesByName={selectedFacetValuesByName}
      />
    );
  };

  const renderHelp = (s) => {
    if (helpText.hasOwnProperty(s)) {
      return helpText[s];
    }

    return null;
  };

  const getActions = (facet: FavoriteFacet) => {
    const result = [];

    const onClick = () => {
      const { logsMetricsQueryState } = logsAnalytics;
      const { forceQueries, clearFormulas, formulas } = logsMetricsQueryState;
      const step = getRollupByVisualization(date, 'bar');
      const stepSeconds = Math.max(1, step);
      const facetKey = getFacetKey(facet);
      if (formulas?.length) {
        clearFormulas();
      }
      const isComponentLabel = getIsComponentLabelStrict(facet.component);
      const isDuration = getIsLogRangeFacet(facet.type);
      const filters = [...logsState.filtersState.state];

      const sourceFilter = {
        type: FilterType.selectedFacetValue,
        value: {
          facet: `Core${delimiter}source${delimiter}STRING`,
          operator: Operator.equal,
          values: {
            [facet.source]: 1,
          },
        },
      };

      if (isDuration) {
        const durationFacetKey = `@${facet.name}${delimiter}${facet.type}${delimiter}${facet.displayName || ''}`;
        forceQueries({
          rangeAggregateGrouping: [],
          step: `${stepSeconds}s`,
          metric: durationFacetKey,
          vectorAggregate: 'avg',
          rangeAggregate: 'avg_over_time',
          defaultMetricOptions: [
            { label: `@${facet.name}`, value: durationFacetKey },
          ],
          filters: facet.source === 'source' ? [sourceFilter] : [],
        });
      } else if (!isComponentLabel) {
        forceQueries({
          rangeAggregateGrouping: [facetKey],
          filters: facet.source ? [sourceFilter] : [],
          step: `${stepSeconds}s`,
          defaultRangeAggregateGroupingOptions: [
            { label: `@${facet.displayName || facet.name}`, value: facetKey },
          ],
        });
      } else {
        const aggregateFacetKey = `${facet.component}${delimiter}${facet.name}${delimiter}${facet.type}`;
        forceQueries({
          rangeAggregateGrouping: [aggregateFacetKey],
          step: `${stepSeconds}s`,
          filters: [...filters],
        });
      }
      navigate(`/logs/${DashboardPanelType.TIMESERIES}?${params.toString()}`);
    };

    result.push({
      label: (
        <TooltipTrigger
          asChild={true}
          variant="compact"
          tooltip={`Show analytics for ${facet.name}`}
          className="flex size-full items-center justify-center"
        >
          <Icon icon="line-chart" size="sm" />
        </TooltipTrigger>
      ),
      onClick,
    });

    if (!getIsComponentLabelStrict(facet.component)) {
      const isKeyExistsEnabled = Boolean(keyExists[getFacetKey(facet)]);
      const tooltip = isKeyExistsEnabled
        ? `Remove key exists filter for ${facet.name}`
        : `Show logs where ${facet.name} exists`;

      result.push({
        label: (
          <TooltipTrigger
            asChild={true}
            variant="compact"
            tooltip={tooltip}
            onClick={(e) => {
              toggleKeyExists(facet);
              e.stopPropagation();
            }}
            className="flex size-full items-center justify-center"
          >
            <Icon icon="key" size="sm" />
          </TooltipTrigger>
        ),
        show: isKeyExistsEnabled,
      });
    }

    if (!getIsComponentLabelStrict(facet.component)) {
      result.push({
        label: (
          <TooltipTrigger
            asChild={true}
            variant="compact"
            tooltip={'Edit facet'}
            onClick={(e) => {
              modal.push(
                <LogsFacetExplorerEditFavorite
                  closeModal={modal.pop}
                  enableRemoveFavorite={true}
                  facetName={facet.name}
                  facetType={facet.type}
                  facetSource={facet.source}
                  facet={facet}
                  onSaveFavoriteSuccess={(group) => {
                    setRefreshFacetGroups(group);
                  }}
                />,
              );
              e.stopPropagation();
            }}
            className="flex size-full items-center justify-center"
          >
            <Icon icon="edit" size="sm" />
          </TooltipTrigger>
        ),
        show: false,
      });
    }

    return result;
  };

  const handleUseFacetsWithChange = (queryIndex: string) => {
    setSyncSelectedQuery((prevState) => ({
      ...prevState,
      queryIndex: Number(queryIndex) || 0,
      lastRefreshedAt: new Date().valueOf(),
    }));
  };

  const facetsOfOptions = useMemo(() => {
    return chartQueries.map((query, index) => {
      const value = query.queryKey;
      return {
        label: `Query ${value}`,
        value: String(index),
      };
    });
  }, [chartQueries]);

  return (
    <>
      {isAnalyticsView && queries.length > 1 && (
        <div className="button-group log-analytics-facets-query-selector ml-2.5">
          <div className="button-group__item button-group__item--label">
            Use Facets with
          </div>
          <AutocompleteV2
            className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value m-2"
            onChange={handleUseFacetsWithChange}
            options={facetsOfOptions}
            value={String(
              syncSelectedQuery.queryIndex ? syncSelectedQuery.queryIndex : 0,
            )}
          />
        </div>
      )}
      <FacetPicker
        actions={getActions(CoreLevelLabel)}
        keyExists={keyExists}
        forceExpanded
        lastRefreshedAt={queryScheduler.secondQueryCompletedAt}
        name={getFacetKey(CoreLevelLabel)}
        info={renderHelp(CoreLevelLabel.name)}
        onExpansionChange={queryScheduler.setIsLogLevelOpen}
        renderName={() => CoreLevelLabel.name}
        renderValue={(value) => (
          <ChipWithLabel color={colorsByLogLevel[value]} label={value} />
        )}
        renderPlaceholderText={renderLabelPlaceholderText}
        {...requestByLogLevelFacet(CoreLevelLabel)}
        selectedFacetValues={
          selectedFacetValuesByName[getFacetKey(CoreLevelLabel)] || {}
        }
        selectedFacetRange={null}
        {...handlersByName(CoreLevelLabel)}
        toggleKeyExists={null}
      />
      <FacetPicker
        actions={getActions(CoreSourceLabel)}
        keyExists={keyExists}
        lastRefreshedAt={queryScheduler.secondQueryCompletedAt}
        name={getFacetKey(CoreSourceLabel)}
        info={renderHelp(CoreSourceLabel.name)}
        renderName={() => CoreSourceLabel.name}
        renderPlaceholderText={renderLabelPlaceholderText}
        {...requestByFacet(CoreSourceLabel)}
        selectedFacetValues={
          selectedFacetValuesByName[getFacetKey(CoreSourceLabel)] || {}
        }
        selectedFacetRange={null}
        {...handlersByName(CoreSourceLabel)}
        toggleKeyExists={null}
      />
      <div className="left-sidebar__divider" />
      <LeftSidebarSectionHeader iconName="labels">
        Labels
      </LeftSidebarSectionHeader>

      {error.getLabelNames ? (
        <div className="flex gap-[4px] w-full justify-start ml-[4px] pr-[14px]">
          <IoIosWarning
            className="overlay-message__icon-and-message__icon"
            size={16}
          />
          <div className="text-red-500">{error.getLabelNames.message}</div>
        </div>
      ) : (
        <>
          {renderGroup({ facets: cloudLabels, group: 'Cloud' })}
          {renderGroup({ facets: kubernetesLabels, group: 'Kubernetes' })}
          {renderGroup({ facets: additionalLabels, group: 'Additional' })}
        </>
      )}

      <div className="left-sidebar__divider" />
      <LeftSidebarSectionHeader iconName="sources" className="pb-2">
        <div className="flex justify-between">
          <div>Facets</div>
          <div
            className={classNames({
              'h-[24px] w-[24px] flex items-center justify-center rounded-sm text-icon hover:bg-interaction-primary hover:text-white':
                true,
            })}
          >
            <TooltipTrigger
              asChild={true}
              variant="compact"
              tooltip={'Open Facet Explorer'}
              onClick={() => {
                navigate('/logs/facet-explorer');
              }}
              className="flex size-full cursor-pointer items-center justify-center"
            >
              <ExternalLinkIcon size={16} />
            </TooltipTrigger>
          </div>
        </div>
      </LeftSidebarSectionHeader>

      <SearchInput
        containerClassName="mr-3 mt-1 mb-2 ml-2"
        onChange={(e) => {
          const value = e.target.value;
          sourcesSimpleSearch.setSearch(value);
        }}
        placeholder="Search groups"
        value={sourcesSimpleSearch.search}
      />
      <Loader isLoading={logsFavouriteFacetGroupsRequest.isLoading}></Loader>

      {/* TODO: move this into each LogsFacetGroup and show error on expand */}
      {error.getFavoriteFacetGroup && (
        <div className="flex gap-[4px] w-full justify-start ml-2">
          <IoIosWarning
            className="overlay-message__icon-and-message__icon"
            size={16}
          />
          <div className="text-red-500">
            {error.getFavoriteFacetGroup.message}
          </div>
        </div>
      )}

      {sourcesSimpleSearch.searchedItems
        .sort((a, b) => a.localeCompare(b))
        .map((component: string) => (
          <LogsFacetGroup
            component={component}
            key={component}
            getActions={getActions}
            isDeleteable={true}
            handlersByName={handlersByName}
            logsState={logsState}
            queryScheduler={queryScheduler}
            renderNameHandler={renderNameHandler}
            renderPlaceholderText={renderPlaceholderText}
            requestByFacet={requestByFacet}
            refreshFacetGroups={refreshFacetGroups}
            setRefreshFacetGroups={setRefreshFacetGroups}
            logsFavouriteFacetGroupsRequest={logsFavouriteFacetGroupsRequest}
            selectedFacetRanges={selectedFacetRanges}
            selectedFacetValuesByName={selectedFacetValuesByName}
          />
        ))}
    </>
  );
};

export default LogsSidebar;

import { delimiter } from 'kfuse-constants';
import {
  DashboardPanelType,
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  LogQLWithMetaProps,
} from 'types';
import {
  buildLogql,
  buildLogqlFormula,
  getRollupToSecond,
  getStepFromDate,
  getVectorAggrLegend,
  getRollupByVisualization,
} from 'utils';
import { getUsedQueryKeysInFormulas } from 'utils/FormulaValidator/validateLogqlFormulaExpression';

const getLogQLWithMetaToLoad = ({
  customerFilter,
  dataFormat,
  date,
  formulas,
  formulaOnly,
  instant,
  queries,
}: {
  customerFilter?: { key: string; value: string };
  dataFormat: DashboardPanelType;
  date: DateSelection;
  formulas: LogsMetricForumlaProps[];
  formulaOnly: boolean;
  instant: boolean;
  queries: LogsMetricQueryProps[];
}): LogQLWithMetaProps[] => {
  const logQLWithMeta: LogQLWithMetaProps[] = [];
  const { startTimeUnix, endTimeUnix } = date;

  const defaultStep = getRollupByVisualization(date, 'bar');
  const getStep = (query: LogsMetricQueryProps) => {
    if (instant) {
      return endTimeUnix - startTimeUnix;
    }
    if (query.step) return getRollupToSecond(query.step);
    return defaultStep;
  };

  const formulaExpressions = formulas.map((formula) => formula.expression);
  const usedKeysInFormulas = getUsedQueryKeysInFormulas(formulaExpressions);

  queries
    .filter((q) => q.isActive || usedKeysInFormulas.includes(q.queryKey))
    .forEach((query: LogsMetricQueryProps) => {
      if (!formulaOnly || usedKeysInFormulas.includes(query.queryKey)) {
        let logql = [];
        const step = getStep(query);
        const { rangeAggregateGrouping, normalizeFunction } = query;
        if (query.showInput) {
          logql = [query.logql];
        } else {
          logql = buildLogql({
            customerFilter,
            queries: [query],
            step: `${step}s`,
          });
        }

        if (logql && logql[0]) {
          const labels =
            rangeAggregateGrouping?.map((label) => label.split(delimiter)[1]) ||
            [];
          const aggregateLegend = getVectorAggrLegend({
            query,
            selectedVal: query.vectorAggregate,
          });
          logQLWithMeta.push({
            logql: logql[0],
            meta: {
              aggregate: aggregateLegend,
              refId: query.queryKey,
              type: dataFormat,
              step: step,
              metricName: aggregateLegend,
              labels,
              unit: normalizeFunction === 'duration' ? 's' : normalizeFunction,
              queryType: 'query',
            },
            limit: query.limit,
            queryType: 'query',
          });
        }
      }
    });

  formulas.forEach((formula: LogsMetricForumlaProps) => {
    const logql = buildLogqlFormula({
      customerFilter,
      formulas: [formula],
      queries,
      step: instant ? getStepFromDate({ date }) : `${defaultStep}s`,
    });

    if (logql && logql[0]) {
      logQLWithMeta.push({
        logql: logql[0],
        meta: {
          aggregate: `Value_of_${formula.queryKey}`,
          refId: formula.queryKey,
          type: dataFormat,
          step: defaultStep,
          metricName: `formula_of_${formula.queryKey}`,
          unit: 'number',
          formulaExpression: formula.expression,
          queryType: 'formula',
        },
        queryType: 'formula',
      });
    }
  });

  return logQLWithMeta;
};

export default getLogQLWithMetaToLoad;

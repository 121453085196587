import dayjs from 'dayjs';
import queryRumService from './queryRumService';
import { SelectedFacetValuesByName } from 'types/selectedFacets';
import { buildRumFilter } from './utils';
import { formatNs } from 'utils/timeNs';
import { TimeUnit } from 'types';

type Args = {
  endTimeUnix: number;
  startTimeUnix: number;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  aggregateFunction: string;
};

const rumAggregateTable = async ({
  endTimeUnix,
  startTimeUnix,
  selectedFacetValuesByName,
  aggregateFunction,
}: Args): Promise<any> => {
  const endTime = dayjs.unix(endTimeUnix);
  const durationSecs = endTimeUnix - startTimeUnix;
  const rawData = await queryRumService<any, 'aggregateTable'>(`
    query {
      aggregateTable(
        eventType: VIEW,
        timestamp: "${endTime.toISOString()}"
        durationSecs: ${durationSecs}
        aggregates: [
          {
            field: "view.loading_time"
            aggregation: "${aggregateFunction}",
          },
          {
            field: "view.largest_contentful_paint"
            aggregation: "${aggregateFunction}",
          },
          {
            field: "view.cumulative_layout_shift"
            aggregation: "${aggregateFunction}",
          },
          {
            field: "view.interaction_to_next_paint"
            aggregation: "${aggregateFunction}",
          }
        ]
        filter: ${buildRumFilter({
          selectedFacetValuesByName,
        })}
        groupBy: [
          {
            field: "view.url_path"
            limit: 10
            sort: {
              field: "*"
              aggregation: "count"
              order: "top"
            }
          }
        ]
      ) {
        dimensions
        aggregates
      }
    }
  `);

  if (!rawData?.aggregateTable || rawData.aggregateTable.length === 0) {
    return { data: {} };
  }

  const formattedAggregates = rawData.aggregateTable.reduce(
    (acc, item) => {
      acc['view.loading_time'] =
        `${formatNs(item.aggregates[0], TimeUnit.SECONDS, 2)}s`;
      acc['view.largest_contentful_paint'] =
        `${formatNs(item.aggregates[1], TimeUnit.SECONDS, 2)}s`;
      acc['view.cumulative_layout_shift'] = item.aggregates[2]?.toFixed(2);
      acc['view.interaction_to_next_paint'] =
        `${formatNs(item.aggregates[3], TimeUnit.SECONDS, 2)}s`;
      acc['view.url_path'] = item.dimensions['view.url_path'];
      return acc;
    },
    {} as Record<string, string>,
  );

  return [
    {
      data: formattedAggregates,
    },
  ];
};

export default rumAggregateTable;

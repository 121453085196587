import {
  Orientation,
  ServiceMap as ServiceMapComponent,
  ServiceServiceMapNodeLabel,
  ServiceWithLabels,
} from 'components';
import { useRequest } from 'hooks';
import React, { useEffect, useMemo } from 'react';
import { MarkerType } from 'reactflow';
import { queryRange } from 'requests';
import {
  DateSelection,
  PrometheusDataset,
  SelectedFacetValuesByName,
  Service,
} from 'types';
import {
  buildPromQLClausesFromSelectedFacetValuesByName,
  resolveColorFromMap,
} from 'utils';
import ServiceMapLinkTooltip from './ServiceMapLinkTooltip';
import ServiceMapNodeTooltip from './ServiceMapNodeTooltip';
import { getTimeParameter } from '../utils';

type ServiceMapQueryArgs = {
  date: DateSelection;
  isDownStream: boolean;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
};

const serviceMapQuery = ({
  date,
  isDownStream,
  selectedFacetValuesByName,
  serviceHash,
}: ServiceMapQueryArgs) => {
  const timeParameter = getTimeParameter(date);
  const filters = buildPromQLClausesFromSelectedFacetValuesByName({
    isDownStream,
    selectedFacetValuesByName,
  }).join(',');
  const filtersString = filters ? `,${filters}` : '';

  const serviceHashKey = isDownStream ? 'client_service_hash' : 'service_hash';

  return `sum by (client_service_hash, client_service_name, service_hash, service_name) (rate(edge_latency_count{${serviceHashKey}="${serviceHash}"${filtersString}}[${timeParameter}]))`;
};

export interface Datum {
  metric: Metric;
  value: [number, string];
}

export interface Metric {
  client_service_hash: string;
  service_hash: string;
}

const formatDataset = (result: PrometheusDataset[]) => {
  const edgeById: { [id: string]: any } = {};
  const nodeById: { [serviceHash: string]: any } = {};
  result.forEach((dataset) => {
    const { metric } = dataset;
    const { client_service_name, service_name } = metric;

    const client_service_hash =
      metric.client_service_hash === 'UNKNOWN'
        ? `UNKNOWN-${client_service_name}`
        : metric.client_service_hash;

    const service_hash =
      metric.service_hash === 'UNKNOWN'
        ? `UNKNOWN-${service_name}`
        : metric.service_hash;

    if (client_service_hash && !nodeById[client_service_hash]) {
      nodeById[client_service_hash] = {
        id: client_service_hash,
        data: {
          attributes: {
            ...metric,
            service_name: metric.client_service_name,
          },
          label: client_service_hash,
        },
      };
    }

    if (!nodeById[service_hash]) {
      nodeById[service_hash] = {
        id: service_hash,
        data: {
          attributes: metric,
          label: service_hash,
        },
      };
    }

    if (client_service_hash && client_service_hash !== service_hash) {
      const edgeIds = [service_hash, client_service_hash];
      const edgeId = edgeIds.sort().join('->');
      if (!edgeById[edgeId]) {
        edgeById[edgeId] = {
          source: client_service_hash,
          target: service_hash,
          clientServiceName: client_service_name,
          serviceName: service_name,
          markerEnd: {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#FF0072',
          },
        };
      } else {
        const edge = edgeById[edgeId];
        const { source } = edge;
        if (source !== client_service_hash) {
          edge.markerStart = {
            type: MarkerType.ArrowClosed,
            width: 20,
            height: 20,
            color: '#FF0072',
          };
        }
      }
    }
  });

  const edges = Object.values(edgeById);
  const nodes = Object.values(nodeById);

  return { edges, nodes };
};

type Props = {
  colorsByServiceName: { [key: string]: string };
  date: DateSelection;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  serviceHash: string;
  serviceByHash: Record<string, Service>;
};

const fetchServiceMap = ({
  date,
  selectedFacetValuesByName,
  isDownStream,
  serviceHash,
}) =>
  queryRange({
    date,
    instant: true,
    query: serviceMapQuery({
      date,
      selectedFacetValuesByName,
      isDownStream,
      serviceHash,
    }),
  });

const ServiceMap = ({
  colorsByServiceName,
  date,
  selectedFacetValuesByName,
  serviceHash,
  serviceByHash,
}: Props) => {
  const queryRangeRequest = useRequest(async (args) => {
    const resultSets = await Promise.all([
      fetchServiceMap({
        date: args.date,
        isDownStream: false,
        selectedFacetValuesByName,
        serviceHash,
      }),
      fetchServiceMap({
        date: args.date,
        isDownStream: true,
        selectedFacetValuesByName,
        serviceHash,
      }),
    ]);

    const dataset = [...resultSets[0], ...resultSets[1]];
    return formatDataset(dataset);
  });

  const { edges, nodes } = useMemo(() => {
    const initialNodes = queryRangeRequest.result?.nodes || [];
    const initialEdges = queryRangeRequest.result?.edges || [];

    return {
      nodes: initialNodes.map((node) => {
        const service = serviceByHash[node.id];
        const serviceName = node.data.attributes.service_name;
        const name = service?.name || serviceName || node.id;
        const distinctLabels = service?.distinctLabels || {};
        const labels = service?.labels || {};

        return {
          ...node,
          data: {
            ...node.data,
            color: resolveColorFromMap(colorsByServiceName, name),
            label: name,
            optionLabel: (
              <ServiceWithLabels
                color={resolveColorFromMap(colorsByServiceName, name)}
                name={name}
                distinctLabels={distinctLabels}
                labels={labels}
              />
            ),
            renderLabel: ({ search }) => (
              <ServiceServiceMapNodeLabel
                distinctLabels={distinctLabels}
                labels={labels}
                name={service ? name : node.data.attributes.service_name}
                search={search}
                selectedFacetValuesByName={selectedFacetValuesByName}
                serviceByHash={serviceByHash}
                serviceHash={node.id}
                showLink={Boolean(service)}
              />
            ),
            serviceName,
            subLabel: Object.keys(distinctLabels)
              .map((label) => distinctLabels[label])
              .join(', '),
          },
          searchValue: service
            ? JSON.stringify(service).toLowerCase()
            : node.id,
        };
      }),
      edges: initialEdges,
    };
  }, [
    queryRangeRequest.result,
    colorsByServiceName,
    selectedFacetValuesByName,
    serviceByHash,
  ]);

  useEffect(() => {
    queryRangeRequest.call({
      date,
      selectedFacetValuesByName,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date, selectedFacetValuesByName]);

  const upstreamBitmap = useMemo(() => {
    return edges
      .filter((edge) => edge.target === serviceHash)
      .reduce(
        (obj, edge) => ({
          ...obj,
          [edge.source]: 1,
        }),
        {},
      );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [edges]);

  return (
    <div style={{ height: '500px' }}>
      <ServiceMapComponent
        initialEdges={edges}
        initialNodes={nodes}
        orientation={Orientation.horizontal}
        renderNodeTooltip={(id, node) => {
          return (
            <ServiceMapNodeTooltip
              date={date}
              isUpstream={Boolean(upstreamBitmap[id])}
              name={serviceByHash[id]?.name || node.label || id}
              serviceHash={id}
              serviceName={node.serviceName}
            />
          );
        }}
        renderEdgeTooltip={(edge) => (
          <ServiceMapLinkTooltip
            clientServiceName={edge.clientServiceName}
            clientServiceHash={edge.source}
            date={date}
            name={`${serviceByHash[edge.source]?.name || edge.clientServiceName || edge.source}-${
              serviceByHash[edge.target]?.name ||
              edge.serviceName ||
              edge.target
            }`}
            serviceHash={edge.target}
            serviceName={edge.serviceName}
          />
        )}
      />
    </div>
  );
};

export default ServiceMap;

import { SearchTag } from 'components';
import { SelectedRangeFilter } from 'hooks';
import React from 'react';
import { formatDurationNs, getRangeText, parseFacetKey } from 'utils';

type Args = {
  filter: SelectedRangeFilter;
  renderFacet: (facet: string) => string;
};

const defaultGetRangeRag = ({
  filter,
  renderFacet,
}: Args): Pick<SearchTag, 'label' | 'text'> => {
  const { facet, isLowerInclusive, isUpperInclusive, lower, upper } =
    filter.value;

  const renderedFacet = renderFacet ? renderFacet(facet) : facet;
  const parsedFacet = parseFacetKey(facet);
  const type = parsedFacet.type?.toLowerCase();
  let text = '';
  if (type === 'number' || type === 'bytes') {
    text = getRangeText({
      lower,
      upper,
      isLowerInclusive,
      isUpperInclusive,
      name: renderedFacet,
    });
  } else {
    text = getRangeText({
      lower:
        typeof lower === 'number'
          ? formatDurationNs(lower * 1000000, 1, 2)
          : null,
      upper:
        typeof upper === 'number'
          ? formatDurationNs(upper * 1000000, 1, 2)
          : null,
      isLowerInclusive,
      isUpperInclusive,
      name: renderedFacet,
    });
  }

  const label = (
    <>
      <span className="traces-search__input__trigger__tag__name">{text}</span>
    </>
  );

  return {
    label,
    text,
  };
};

export default defaultGetRangeRag;

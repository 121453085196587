import delimiter from 'kfuse-constants/delimiter';
import { LogsMetricForumlaProps, LogsMetricQueryProps } from 'types';
import { buildLogql, buildLogqlFormula } from 'utils';

/**
 * Get the grouped label and facet
 * @param rangeAggregateGrouping string[]
 * @returns
 */
export const getGroupedLabelAndFacet = (
  rangeAggregateGrouping: string[],
): string[] => {
  return rangeAggregateGrouping.map((grouping) => {
    const [component, name] = grouping.split(delimiter);
    if (!component || !name) {
      return grouping;
    }
    if (['Cloud', 'Core', 'Kubernetes', 'Additional'].includes(component)) {
      return `${component}:${name}`;
    }
    return `@${component}:${name}`;
  });
};

/**
 * get the label and facet
 * @param labelAndFacet string []
 * @example Cloud:cluster_name => cluster_name
 * @example @agent:body => @body
 */
export const getLabelAndFacetOnly = (labelAndFacet: string[]): string => {
  const labelAndFacetList = labelAndFacet.map((label) => {
    const [_, name] = label.split(':');
    if (label.charAt(0) === '@') {
      return `@${name}`;
    }
    return name;
  });
  return labelAndFacetList.join(',');
};

export const getLogqlForChartModal = ({
  customerFilter,
  formula,
  logqlType,
  query,
  queries,
  type,
}: {
  customerFilter?: { key: string; value: string };
  formula?: LogsMetricForumlaProps;
  logqlType: 'save' | 'load';
  query?: LogsMetricQueryProps;
  queries?: LogsMetricQueryProps[];
  type: 'query' | 'formula';
}): string => {
  if (type === 'query') {
    const { logql, step, showInput } = query;
    const newStep = logqlType === 'save' ? '1m' : step;
    if (showInput) {
      return logql;
    }

    const newLogql = buildLogql({
      customerFilter,
      queries: [query],
      step: newStep,
    });
    if (!newLogql || !newLogql[0]) {
      return '';
    }
    return newLogql[0];
  }

  if (type === 'formula') {
    const newStep = logqlType === 'save' ? '1m' : queries[0].step;
    const newLogql = buildLogqlFormula({
      customerFilter,
      formulas: [formula],
      queries: queries,
      step: newStep,
    });
    if (!newLogql || !newLogql[0]) {
      return '';
    }
    return newLogql[0];
  }

  return '';
};

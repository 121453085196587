import { Skeleton } from 'components';
import React, { ReactNode, useEffect, useMemo } from 'react';
import {
  FacetBase,
  FacetName,
  FacetPickerAction,
  SelectedFacetRangeByName,
  SelectedFacetValuesByName,
} from 'types';
import { getIsComponentLabel } from 'utils';
import { SearchInput } from 'components/FacetPicker/FacetPickerValues';
import LogsFacetGroupExpandedFacets from './LogsFacetGroupExpandedFacets';
import {
  useLogsFacetSearch,
  useLogsWorkbooksState,
  useQueryScheduler,
} from './hooks';

type Props = {
  component: string;
  getActions: (facet: FacetBase) => FacetPickerAction[];
  hardcodedFacets?: FacetName[];
  handlersByName: (facet: FacetBase) => {
    clearFacet: VoidFunction;
    changeFacetRange: VoidFunction;
    excludeFacetValue: (value: string) => void;
    selectOnlyFacetValue: (value: string) => void;
    toggleFacetValue: (value: string, values: any[]) => void;
    toggleKeyExists: () => void;
  };
  hideAutogeneratedFacets?: boolean;
  logsState: ReturnType<typeof useLogsWorkbooksState>['currentLogsState'];
  queryScheduler: ReturnType<typeof useQueryScheduler>;
  renderNameHandler: (facet: FacetBase) => () => ReactNode;
  renderPlaceholderText: (facetKey: string) => string;
  requestByFacet: (
    facet: FacetBase,
  ) => Promise<{ count: number; value: string }[]>;
  refreshFacetGroups: string;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  selectedFacetRanges: SelectedFacetRangeByName;
  setRefreshFacetGroups: (component: string) => void;
};

const LogsFacetGroupExpanded = ({
  component,
  getActions,
  handlersByName,
  hardcodedFacets,
  hideAutogeneratedFacets,
  logsState,
  requestByFacet,
  queryScheduler,
  refreshFacetGroups,
  renderNameHandler,
  renderPlaceholderText,
  selectedFacetRanges,
  selectedFacetValuesByName,
  setRefreshFacetGroups,
}: Props) => {
  const logsFacetSearch = useLogsFacetSearch({
    component,
    hardcodedFacets,
    hideAutogeneratedFacets,
    logsState,
  });
  const {
    isFacetSearchLoading,
    loadFacetOnSearchChange,
    onFacetSearchChange,
    setFacetSearch,
  } = logsFacetSearch;

  useEffect(() => {
    if (!hardcodedFacets) {
      if (queryScheduler.secondQueryCompletedAt) {
        loadFacetOnSearchChange('');
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [queryScheduler.secondQueryCompletedAt]);

  useEffect(() => {
    if (refreshFacetGroups === component) {
      loadFacetOnSearchChange('');
      setRefreshFacetGroups('');
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [refreshFacetGroups]);

  const { keyExists } = logsState;

  const shouldFilterFacets = Boolean(hardcodedFacets?.length);
  const { facets, facetSearch } = logsFacetSearch;

  const filteredFacets = useMemo(() => {
    if (!shouldFilterFacets) return facets;
    return facets.filter((facet) => {
      return facet.name.toLowerCase().includes(facetSearch.toLowerCase());
    });
  }, [facets, shouldFilterFacets, facetSearch]);

  if (isFacetSearchLoading && filteredFacets?.length === 0) {
    return (
      <div className="mx-2 mb-2">
        <Skeleton count={3} />
      </div>
    );
  }

  return (
    <>
      <SearchInput
        containerClassName="ml-1 mr-3 mt-1 mb-2"
        onChange={(e) => {
          const value = e.target.value;
          onFacetSearchChange(value);
          setFacetSearch(value);
        }}
        placeholder={`Search ${component} ${
          getIsComponentLabel(component) ? '' : 'facets'
        }`}
        value={logsFacetSearch.facetSearch}
      />
      <LogsFacetGroupExpandedFacets
        component={component}
        customerFilter={logsState.customerFilter}
        getActions={getActions}
        handlersByName={handlersByName}
        keyExists={keyExists}
        logsFacetSearch={logsFacetSearch}
        queryScheduler={queryScheduler}
        renderNameHandler={renderNameHandler}
        renderPlaceholderText={renderPlaceholderText}
        requestByFacet={requestByFacet}
        selectedFacetValuesByName={selectedFacetValuesByName}
        selectedFacetRanges={selectedFacetRanges}
        shouldFilterFacets={shouldFilterFacets}
        filteredFacets={filteredFacets}
      />
    </>
  );
};

export default LogsFacetGroupExpanded;

import classNames from 'classnames';
import { debounce } from 'lodash';
import {
  AutocompleteOption,
  AutocompleteV2,
  Button,
  MultiselectV2,
  TooltipTrigger,
} from 'components';
import {
  logsTopBottomCountOptions,
  logsRollupOptionsByRange,
} from 'kfuse-constants';
import { useLogsMetricsQueryState } from 'hooks';
import React, { ReactElement, useCallback, useEffect, useMemo } from 'react';
import { Code, XCircle } from 'react-feather';
import { MdTune } from 'react-icons/md';
import { components } from 'react-select';
import { DashboardPanelType, DateSelection, LogsMetricQueryProps } from 'types';
import {
  getLabelAndFacetGroupingOptions,
  getRollupToSecond,
  getVectorAggregateOptions,
  getVectorAggrText,
  convertSecondToReadable,
  getRollupByVisualization,
} from 'utils';

import LogsMetricsQueryBuilderInput from './LogsMetricsQueryBuilderInput';
import LogsMetricsQueryBuilderQueryOptions from './LogsMetricsQueryBuilderQueryOptions';
import LogsMetricsQueryBuilderSearch from './LogsMetricsQueryBuilderSearch';
import { ALLOW_ONLY_ONE_ACTIVE_SEARCH } from 'screens/Traces/utils';

const DISABLE_LOGQL_EDIT = true;

const isVectorAggrDisabled = (query: LogsMetricQueryProps) => {
  const vectorText = getVectorAggrText({ query, selectedVal: '' });
  if (vectorText === 'count of' || vectorText === 'count unique of')
    return true;

  return false;
};

const LogsMetricsQueryBuilderQueryAction = ({
  logsMetricsQueryState,
  query,
  queryIndex,
}: {
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  query: LogsMetricQueryProps;
  queryIndex: number;
}): ReactElement => {
  const { queries, removeQuery, updateQuery } = logsMetricsQueryState;

  return (
    <div className="metrics__query-builder__query-action">
      {!DISABLE_LOGQL_EDIT && (
        <TooltipTrigger tooltip={query.showInput ? 'Use Builder' : 'Use LogQL'}>
          <div className="metrics__query-builder__query-action__icon">
            <div className="metrics__query-builder__query-action__icon--code">
              {query.showInput ? (
                <MdTune
                  onClick={() => updateQuery(queryIndex, 'showInput', false)}
                />
              ) : (
                <Code
                  onClick={() => updateQuery(queryIndex, 'showInput', true)}
                />
              )}
            </div>
          </div>
        </TooltipTrigger>
      )}
      {queries.length > 1 && queryIndex !== 0 && (
        <TooltipTrigger tooltip="Remove query">
          <Button
            className="metrics__query-builder__query-action__icon--delete h-full px-1.5"
            variant="icon"
            size="sm"
          >
            <XCircle onClick={() => removeQuery(queryIndex)} size={16} />
          </Button>
        </TooltipTrigger>
      )}
    </div>
  );
};

const LogsMetricsQueryBuilderQuery = ({
  activeVisualization,
  allowMultipleQueries,
  customerFilter,
  date,
  disableSearch,
  logsMetricsQueryState,
  minStep,
  queryIndex,
  query,
}: {
  activeVisualization?: string;
  allowMultipleQueries?: boolean;
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  disableSearch?: boolean;
  logsMetricsQueryState: ReturnType<typeof useLogsMetricsQueryState>;
  minStep?: string;
  queryIndex: number;
  query: LogsMetricQueryProps;
}): ReactElement => {
  const {
    deactivateAllFormulas,
    formulas,
    facetNamesOptionsBitmap,
    handleLoadQueryWithState,
    handleScrollToBottomFacetNames,
    loadLogsAffectedFormula,
    loadFacetNameOnInputChange,
    loadInitalFacetNames,
    loadLogqlQuery,
    queries,
    searchBarRect,
    updateQuery,
    setQueries,
  } = logsMetricsQueryState;

  const { metric, showInput, step, queryKey } = query;
  const groupingOptions = useMemo(() => {
    return getLabelAndFacetGroupingOptions(
      facetNamesOptionsBitmap[queryKey]?.aggregate?.options || [],
    );
  }, [facetNamesOptionsBitmap, queryKey]);

  /* eslint-disable react-hooks/exhaustive-deps */
  useEffect(() => {
    const step = getRollupByVisualization(date, 'bar');
    const minStepSeconds = getRollupToSecond(minStep);
    const stepSeconds = Math.max(minStepSeconds, step);
    setQueries((queries: LogsMetricQueryProps[]) => {
      const newQueries = [...queries];
      newQueries[queryIndex] = {
        ...newQueries[queryIndex],
        step: convertSecondToReadable(stepSeconds),
      };
      return newQueries;
    });
  }, [date]);

  const logsRollupOptionsAuto = useMemo(() => {
    const logsRollupOptions = logsRollupOptionsByRange(date, minStep);
    const isAutoExist = logsRollupOptions.find(
      (item) => item.value === query.step,
    );
    if (!isAutoExist && step) {
      return [{ value: step, label: `${step} (auto)` }, ...logsRollupOptions];
    }
    return logsRollupOptions;
  }, [query, date]);

  const debouncedLoadFacetNameOnInputChange = useCallback(
    debounce((queryIndex, facet, val) => {
      loadFacetNameOnInputChange(queryIndex, facet, val);
    }, 300),
    [loadFacetNameOnInputChange],
  );

  const queryKeyClickHandler = useCallback(() => {
    const activateOnlySingleQiery = ALLOW_ONLY_ONE_ACTIVE_SEARCH.includes(
      activeVisualization as DashboardPanelType,
    );
    updateQuery(queryIndex, 'isActive', !query.isActive, {
      activateOnlySingleQiery,
    });
    if (activateOnlySingleQiery) {
      const countOfActiveFormulas = formulas.filter(
        (formula) => formula.isActive,
      );
      if (countOfActiveFormulas.length > 0) {
        deactivateAllFormulas();
      }
    }
  }, [activeVisualization, queryIndex, query.isActive, updateQuery]);

  const handleFilterChange = () => {
    handleLoadQueryWithState(queries, queryIndex);
  };

  return (
    <div className="flex--direction-col mb-3 flex">
      {showInput ? (
        <div className="flex">
          <LogsMetricsQueryBuilderInput
            logsMetricsQueryState={logsMetricsQueryState}
            query={query}
            queryIndex={queryIndex}
          />
          <LogsMetricsQueryBuilderQueryAction
            logsMetricsQueryState={logsMetricsQueryState}
            query={query}
            queryIndex={queryIndex}
          />
        </div>
      ) : (
        <div className="flex--direction-col flex">
          {!disableSearch && (
            <div className="flex">
              <div className="width-100-per flex">
                {allowMultipleQueries && (
                  <TooltipTrigger
                    tooltip={query.isActive ? 'Hide Chart' : 'Show Chart'}
                  >
                    <div
                      onClick={queryKeyClickHandler}
                      className={classNames({
                        'query-builder__logs__item__query-key': true,
                        'query-builder__logs__item__query-key--inactive':
                          !query.isActive,
                      })}
                    >
                      {query.queryKey}
                    </div>
                  </TooltipTrigger>
                )}
                <LogsMetricsQueryBuilderSearch
                  customerFilter={customerFilter}
                  date={date}
                  query={query}
                  handleFilterChange={handleFilterChange}
                />
                <LogsMetricsQueryBuilderQueryAction
                  logsMetricsQueryState={logsMetricsQueryState}
                  query={query}
                  queryIndex={queryIndex}
                />
              </div>
            </div>
          )}
          <div
            className={classNames({
              search__grouper: true,
              'query-builder__logs__query-builder__item': !disableSearch,
            })}
            style={
              !disableSearch
                ? {
                    '--search-bar-height': searchBarRect?.height + 'px',
                    '--search-bar-join-line-height':
                      searchBarRect?.height + 'px',
                    '--search-bar-join-line-top':
                      -searchBarRect?.height + 27 + 'px',
                  }
                : {}
            }
          >
            <div className="search__button-group">
              <div className="button-group">
                <div className="button-group__item button-group__item--label">
                  Show
                </div>
                <div className="button-group__item button-group__item--value">
                  <AutocompleteV2
                    className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                    isDisabled={isVectorAggrDisabled(query)}
                    components={{
                      DropdownIndicator: null,
                      SingleValue: ({ ...prop }) => {
                        const propLabel = prop.selectProps.value.label;
                        return (
                          <components.SingleValue {...prop}>
                            {getVectorAggrText({
                              query,
                              selectedVal: propLabel,
                            })}
                          </components.SingleValue>
                        );
                      },
                    }}
                    onChange={(val) =>
                      updateQuery(queryIndex, 'vectorAggregate', val)
                    }
                    isSearchable={false}
                    options={getVectorAggregateOptions(metric)}
                    placeholder="Vector aggregate"
                    value={query.vectorAggregate}
                  />
                </div>
                <div className="button-group__item button-group__item--value">
                  <AutocompleteV2
                    className="autocomplete__fixed-height-30 autocomplete-container--no-border button-group__item__autocomplete--value"
                    components={{ Option: LogsMetricsQueryBuilderQueryOptions }}
                    isLoading={
                      facetNamesOptionsBitmap[queryKey]
                        ? facetNamesOptionsBitmap[queryKey]?.facet?.isLoading
                        : false
                    }
                    isSearchable={true}
                    onChange={(val) => updateQuery(queryIndex, 'metric', val)}
                    onFocus={() => loadInitalFacetNames(queryIndex, 'facet')}
                    onInputChange={(val) =>
                      debouncedLoadFacetNameOnInputChange(
                        queryIndex,
                        'facet',
                        val,
                      )
                    }
                    onMenuScrollToBottom={() =>
                      handleScrollToBottomFacetNames(queryIndex, 'facet')
                    }
                    options={
                      facetNamesOptionsBitmap[queryKey]
                        ? facetNamesOptionsBitmap[queryKey]?.facet?.options
                        : []
                    }
                    fallBackOptions={query?.defaultMetricOptions || []}
                    optimize={true}
                    placeholder="Select a facet"
                    value={query.metric}
                  />
                </div>
              </div>
              <div className="search__button-group__divider">
                <div />
              </div>
              <div className="button-group">
                <div className="button-group__item button-group__item--label">
                  by
                </div>
                <div className="button-group__item button-group__item--value">
                  <MultiselectV2
                    className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                    components={{ Option: LogsMetricsQueryBuilderQueryOptions }}
                    isLoading={
                      facetNamesOptionsBitmap[queryKey]
                        ? facetNamesOptionsBitmap[queryKey]?.aggregate
                            ?.isLoading
                        : false
                    }
                    onChange={(val, options) => {
                      updateQuery(queryIndex, 'rangeAggregateGrouping', val, {
                        defaultRangeAggregateGroupingOptions:
                          options as AutocompleteOption[],
                      });
                    }}
                    optimize={true}
                    placeholder="Everything"
                    value={query.rangeAggregateGrouping}
                    onInputChange={(val) =>
                      debouncedLoadFacetNameOnInputChange(
                        queryIndex,
                        'aggregate',
                        val,
                      )
                    }
                    onMenuScrollToBottom={() => {
                      handleScrollToBottomFacetNames(queryIndex, 'aggregate');
                    }}
                    options={groupingOptions}
                    onFocus={() => {
                      loadInitalFacetNames(queryIndex, 'aggregate');
                    }}
                    fallBackOptions={query.defaultRangeAggregateGroupingOptions}
                  />
                </div>
              </div>
              <div className="search__button-group__divider">
                <div />
              </div>
              <div className="button-group">
                <div className="button-group__item button-group__item--label">
                  limit to
                </div>
                <div className="button-group__item button-group__item--value">
                  <AutocompleteV2
                    className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                    components={{ DropdownIndicator: null }}
                    isSearchable={false}
                    onChange={(val) =>
                      updateQuery(queryIndex, 'limit', {
                        ...query.limit,
                        direction: val,
                      })
                    }
                    options={[
                      { label: 'top', value: 'topk' },
                      { label: 'bottom', value: 'bottomk' },
                    ]}
                    value={query.limit.direction}
                  />
                </div>
                <div className="button-group__item button-group__item--value">
                  <AutocompleteV2
                    className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                    components={{ DropdownIndicator: null }}
                    isSearchable={false}
                    onChange={(val) =>
                      updateQuery(queryIndex, 'limit', {
                        ...query.limit,
                        count: val,
                      })
                    }
                    options={logsTopBottomCountOptions}
                    value={query.limit.count}
                  />
                </div>
              </div>
              <div className="search__button-group__divider">
                <div />
              </div>
              <div className="button-group">
                <div className="button-group__item button-group__item--label">
                  roll up every
                </div>
                <div className="button-group__item button-group__item--value">
                  <AutocompleteV2
                    className="autocomplete-container--no-border autocomplete__fixed-height-30 button-group__item__autocomplete--value"
                    components={{ DropdownIndicator: null }}
                    isSearchable={false}
                    onChange={(val) => updateQuery(queryIndex, 'step', val)}
                    options={logsRollupOptionsAuto}
                    value={query.step}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default LogsMetricsQueryBuilderQuery;

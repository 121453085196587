import {
  Loader,
  SearchInput,
  Table,
  TableBulkActions,
  TableBulkActionsCheckbox,
  TableRow,
  TableRowProps,
  TooltipTrigger,
  useModalsContext,
  useTableBulkActions,
} from 'components';
import { delimiter } from 'kfuse-constants';
import React, { ReactElement, useEffect, useMemo, useState } from 'react';
import { FacetName, FavoriteFacet } from 'types';

import LogsFacetExplorerEditFavorite from './LogsFacetExplorerEditFavorite';
import LogsFacetExplorerSourceFacetsActions from './LogsFacetExplorerSourceFacetsActions';
import LogsFacetGroupFacetIcon from '../Logs/LogsFacetGroupFacetIcon';
import useLogsFacetExplorerState from './useLogsFacetExplorerState';
import { FavoriteFacetExtend } from './types';
import classNames from 'classnames';

const columns = ({
  hoverFacet,
  onMarkFavorite,
  onRemoveFavorite,
  onChartClick,
  onEditFavoriteFacet,
  tableBulkActions,
}: {
  hoverFacet: string;
  onMarkFavorite: (facet: FavoriteFacetExtend) => void;
  onRemoveFavorite: (facet: FavoriteFacetExtend) => void;
  onChartClick: (facet: FavoriteFacetExtend) => void;
  onEditFavoriteFacet: (facet: FavoriteFacetExtend) => void;
  tableBulkActions: ReturnType<typeof useTableBulkActions>;
}) => [
  {
    key: 'bulk-action-checkbox',
    label: (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        isSelectAll
      />
    ),
    renderCell: ({ row }: { row: FacetName }) => (
      <TableBulkActionsCheckbox
        tableBulkActions={tableBulkActions}
        uniqueId={row.uid as string}
      />
    ),
  },
  {
    key: 'name',
    label: 'Facet Name',
    renderCell: ({ row }: { row: FavoriteFacet }) => {
      return (
        <div className="flex gap-1">
          <LogsFacetGroupFacetIcon type={row.type} />
          <TooltipTrigger tooltip={row.name}>
            <div className="w-[280px] max-w-[300px] truncate">
              {row.name}
              <span className="pl-4 font-normal italic text-text-secondary">
                {row.displayName}
              </span>
            </div>
          </TooltipTrigger>
        </div>
      );
    },
  },
  {
    key: 'actions',
    label: '',
    renderCell: ({ row }: { row: FavoriteFacetExtend }) => {
      return (
        <LogsFacetExplorerSourceFacetsActions
          facetWithFavorite={row}
          isFacetHovering={hoverFacet === row.name}
          onChartClick={onChartClick}
          onMarkFavorite={onMarkFavorite}
          onRemoveFavorite={onRemoveFavorite}
          onEditFavoriteFacet={onEditFavoriteFacet}
        />
      );
    },
  },
];

const LogsFacetExplorerSourceFacetsRow = ({
  isRowActive,
  setHoverFacet,
  tableRowProps,
  onRowClick,
}: {
  isRowActive: boolean;
  onRowClick: (row: FavoriteFacetExtend) => void;
  setHoverFacet: (hoverFacet: string) => void;

  tableRowProps: TableRowProps<FavoriteFacetExtend>;
}) => {
  const { columns } = tableRowProps;
  return (
    <TableRow
      {...tableRowProps}
      className={classNames({
        'bg-interaction-secondary': isRowActive,
      })}
      onMouseEnter={() => setHoverFacet(tableRowProps.row.name)}
      onRowClickHandler={() => () => onRowClick(tableRowProps.row)}
    >
      {columns.map((c) => (
        <React.Fragment key={c.key}>
          {c.renderCell(tableRowProps)}
        </React.Fragment>
      ))}
    </TableRow>
  );
};

const LogsFacetExplorerSourceFacets = ({
  logsFacetExplorerState,
}: {
  logsFacetExplorerState: ReturnType<typeof useLogsFacetExplorerState>;
}): ReactElement => {
  const modal = useModalsContext();
  const [facetSearch, setFacetSearch] = useState('');
  const [hoverFacet, setHoverFacet] = useState<string>('');
  const {
    selectedFacet,
    getFacetNamesBySourceRequest,
    getLogsFavoriteFacetRequest,
    makeFavoriteFacetRequest,
    onRemoveFavorite,
    selectedSource,
    setSelectedFacet,
  } = logsFacetExplorerState;

  const facetNamesList = useMemo(() => {
    const facetNames = getFacetNamesBySourceRequest.result || [];
    const favoriteFacets = getLogsFavoriteFacetRequest.result || [];

    if (facetNames.length === 0 && favoriteFacets.length === 0) {
      return [];
    }

    return facetNames.map((f) => {
      const favorite = favoriteFacets.find((fv) => fv.name === f.name);
      return {
        ...f,
        isFavorite: Boolean(favorite),
        displayName: favorite?.displayName,
        group: favorite?.group,
        uid: `${f.name}${delimiter}${f.type}`,
      };
    });
  }, [getFacetNamesBySourceRequest.result, getLogsFavoriteFacetRequest.result]);

  const filteredFacetNamesList = useMemo(() => {
    return facetNamesList.filter((f) =>
      f.name.toLowerCase().includes(facetSearch.toLowerCase()),
    );
  }, [facetNamesList, facetSearch]);

  const tableBulkActions = useTableBulkActions({
    rows: filteredFacetNamesList,
    uniqueIdKey: 'uid',
  });

  const onFacetSearchChange = (value: string) => {
    setFacetSearch(value);
  };

  const onEditFavoriteFacet = (facet: FavoriteFacetExtend) => {
    modal.push(
      <LogsFacetExplorerEditFavorite
        closeModal={modal.pop}
        facet={facet}
        facetSource={selectedSource}
        onSaveFavoriteSuccess={(group) => {
          getLogsFavoriteFacetRequest.call({
            group: selectedSource,
            contains: '',
          });
        }}
      />,
    );
  };

  const onMarkFavorite = (facet: FavoriteFacetExtend) => {
    modal.push(
      <LogsFacetExplorerEditFavorite
        closeModal={modal.pop}
        facetSource={facet.source}
        facetName={facet.name}
        facetType={facet.type}
        onSaveFavoriteSuccess={(group) => {
          makeFavoriteFacetRequest
            .call({
              facet: facet.name,
              facetGroup: selectedSource,
              datatype: facet.type,
              displayName: facet.name,
              source: selectedSource,
            })
            .then((res) => {
              getLogsFavoriteFacetRequest.call({
                group: selectedSource,
                contains: '',
              });
            });
        }}
      />,
    );
  };

  const onMarkFavoriteBulk = (uids: string[]) => {
    const allFacetsBySource = getFacetNamesBySourceRequest.result || [];
    const facetsToMakeFavorite = allFacetsBySource.filter((f) =>
      uids.includes(`${f.name}${delimiter}${f.type}`),
    );
    modal.push(
      <LogsFacetExplorerEditFavorite
        closeModal={modal.pop}
        enableBulkMarkFavorite
        bulkFacets={facetsToMakeFavorite}
        facetSource={selectedSource}
        onSaveFavoriteSuccess={(group) => {
          getLogsFavoriteFacetRequest.call({ group, contains: '' });
          tableBulkActions.clearSelectedRows();
        }}
      />,
    );
  };

  useEffect(() => {
    tableBulkActions.clearSelectedRows();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedSource]);

  const memoizedColumns = useMemo(() => {
    return columns({
      hoverFacet,
      onChartClick: (facet) => setSelectedFacet(facet),
      onEditFavoriteFacet,
      onMarkFavorite,
      onRemoveFavorite,
      tableBulkActions,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hoverFacet, setSelectedFacet, tableBulkActions]);

  return (
    <div className="w-[440px] pt-2">
      <div className="text-lg font-semibold text-text-secondary">
        Facets of {selectedSource}
      </div>
      <SearchInput
        containerClassName="metrics-summary__body__metrics-names__search-bar"
        onChange={onFacetSearchChange}
        placeholder={`Search facet from ${selectedSource}`}
        value={facetSearch}
        type="text"
        size="3"
        dataTestId="metrics-search-bar"
      />
      <div className="metrics-summary__body__seperator" />
      <Loader isLoading={getFacetNamesBySourceRequest.isLoading}>
        <div
          className="metrics-summary__body__metrics-names__result overflow-x-scroll-hide min-h-[500px] overflow-y-scroll"
          data-testid="log-facet-source-facets-table"
        >
          <TableBulkActions
            tableBulkActions={tableBulkActions}
            onBulkDelete={(names) => onMarkFavoriteBulk(names)}
            selectedLabel={`${tableBulkActions.selectedRowsCount > 1 ? 'facets' : 'facet'} selected`}
            buttonTitle="Mark as favorite"
          />
          <Table
            columns={memoizedColumns}
            className="table--bordered table--bordered-cells table__actions--hidden alerts__list__table"
            rows={filteredFacetNamesList}
            renderRow={(tableRowProps) => {
              const { row } = tableRowProps;
              return (
                <LogsFacetExplorerSourceFacetsRow
                  isRowActive={row.name === selectedFacet?.name}
                  onRowClick={setSelectedFacet}
                  setHoverFacet={(name) => setHoverFacet(name)}
                  tableRowProps={tableRowProps}
                />
              );
            }}
          />
        </div>
      </Loader>
    </div>
  );
};

export default LogsFacetExplorerSourceFacets;

import { useToaster } from 'components';
import { useLogsFacetExplorerPageStateContext } from 'context';
import { useRequest } from 'hooks';
import { useEffect, useRef } from 'react';
import { getLogsSources, getLogsFavoriteFacets } from 'requests';
import { DateSelection } from 'types';

import {
  addLogsFavoriteFacet,
  getLogsFacetNames,
  removeLogsFavoriteFacet,
} from './requests';
import { FavoriteFacetExtend } from './types';

const MAX_TIME_RANGE = 60 * 60 * 24; // 1 day

const useLogsFacetExplorerState = () => {
  const { addToast } = useToaster();
  const {
    dateState,
    dependenciesForWriteStateToUrl,
    selectedSourceState,
    selectedFacetState,
    writeStateToUrl,
  } = useLogsFacetExplorerPageStateContext();
  const [date, setDate] = dateState;
  const [selectedSource, setSelectedSource] = selectedSourceState;
  const [selectedFacet, setSelectedFacet] = selectedFacetState;

  const onDateChange = (nextDate: DateSelection) => {
    const { startTimeUnix, endTimeUnix } = nextDate;
    if (endTimeUnix - startTimeUnix > MAX_TIME_RANGE) {
      addToast({
        text: 'Please select a time range less than 1 day',
        status: 'error',
      });
      return;
    }
    setDate(nextDate);
  };

  const getLogsSourcesRequest = useRequest(() =>
    getLogsSources().then((sources) =>
      sources.map((s) => ({ label: s, value: s })),
    ),
  );
  const getFacetNamesBySourceRequest = useRequest(getLogsFacetNames);
  const getLogsFavoriteFacetRequest = useRequest(getLogsFavoriteFacets);
  const makeFavoriteFacetRequest = useRequest(addLogsFavoriteFacet);
  const removeLogsFavoriteFacetRequest = useRequest(removeLogsFavoriteFacet);
  const userActions = useRef<{ initialScrolled: boolean }>({
    initialScrolled: false,
  });

  const onSourceChange = (source: string) => {
    setSelectedSource(source);
    setSelectedFacet(undefined);
    getFacetNamesBySourceRequest.call({ source, limit: 1000 });
    getLogsFavoriteFacetRequest.call({ group: source, contains: '' });
  };

  const onRemoveFavorite = (facet: FavoriteFacetExtend) => {
    removeLogsFavoriteFacetRequest
      .call({
        displayName: facet.displayName,
        facetGroup: facet.group,
      })
      .then((res) => {
        getLogsFavoriteFacetRequest.call({
          group: selectedSource,
          contains: '',
        });
        addToast({
          text: `Facet "${facet.name}" removed as favorite.`,
          status: 'success',
        });
      });
  };

  useEffect(() => {
    if (selectedSource) {
      getFacetNamesBySourceRequest.call({
        source: selectedSource,
        limit: 1000,
      });
      getLogsFavoriteFacetRequest.call({ group: selectedSource, contains: '' });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [date]);

  useEffect(() => {
    writeStateToUrl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, dependenciesForWriteStateToUrl);

  return {
    date,
    getFacetNamesBySourceRequest,
    getLogsFavoriteFacetRequest,
    getLogsSourcesRequest,
    makeFavoriteFacetRequest,
    onDateChange,
    onRemoveFavorite,
    onSourceChange,
    selectedFacet,
    selectedSource,
    setSelectedFacet,
    userActions,
  };
};

export default useLogsFacetExplorerState;

import React, { Dispatch, SetStateAction } from 'react';
import { TraceSidebar } from 'components';
import { useTracesState } from 'hooks';
import { ChartGridKeysState } from 'types';
import { SidebarState } from 'screens/Service/types';

type Props = {
  chartGridKeysState: ChartGridKeysState;
  colorsByServiceHash: { [key: string]: string };
  colorsByServiceName: Record<string, string>;
  sidebar: SidebarState;
  setSidebar: Dispatch<SetStateAction<SidebarState>>;
  tracesState: ReturnType<typeof useTracesState>;
};

const RumRightSidebar = ({
  chartGridKeysState,
  colorsByServiceHash,
  colorsByServiceName,
  sidebar,
  setSidebar,
  tracesState,
}: Props) => {
  const close = () => {
    setSidebar(null);
  };

  if (!sidebar) {
    return null;
  }

  if (sidebar.activeTrace) {
    return (
      <TraceSidebar
        applyFilterOnTracesPage={true}
        chartGridKeysState={chartGridKeysState}
        close={close}
        colorsByServiceHash={colorsByServiceHash}
        colorsByServiceName={colorsByServiceName}
        key={sidebar.activeTrace.span.spanId}
        trace={sidebar.activeTrace}
        tracesState={tracesState}
      />
    );
  }

  return null;
};

export default RumRightSidebar;

import { useTimeseries } from 'components/Timeseries';
import { ReactElement } from 'react';
import uPlot, { Band, Plugin, Series } from 'uplot';

import { CursorStateProps } from './CursorStateContext';
import { DateSelection } from './DateSelection';

export type ChartType = 'Line' | 'Stacked Bar' | 'Area' | 'Points';
export type StrokeType = 'normal' | 'thick' | 'thin' | 'none';
export type LayoutType = 'dashboard' | 'explore' | 'modal' | 'drawer';

export enum LegendTypes {
  AGGREGATE = 'aggregate',
  COMPACT = 'compact',
  COMPACT_ONE_LINE = 'compactOneLine',
  VALUES = 'values',
  SIMPLE = 'simple',
  NONE = 'none',
}

export type OutlierSpanProps = {
  timestamp: number;
  duration: number;
  traceId: string;
  spanCount: number;
  statusCode: string;
};

export type TooltipCoordsProps = {
  renderTooltip?: () => ReactElement;
  data?: { color?: string; label: string; value: string };
  positionX?: 'left' | 'right';
  positionY?: 'top' | 'bottom';
  x: number;
  y: number;
};

export type TooltipDataReturnProps<T = undefined> = {
  tooltip?: TooltipCoordsProps | undefined;
  cursorState?: CursorStateProps | undefined;
  additionalData?: T | undefined;
};

type TimeseriesHookProps = ReturnType<typeof useTimeseries>;

export type TimeseriesToolProps = TimeseriesHookProps & {
  layoutType?: LayoutType;
  onFocusSeries?: (idx: number) => void;
  unit: string;
};

export type UplotChartStyles = {
  fillOpacity?: number;
  gradientMode?: 'none' | 'opacity' | 'hue';
  lineInterpolation?: string;
  lineStyle?: 'solid' | 'dashed' | 'dotted';
  lineWidth?: number;
  pointSize?: number;
  showPoints?: 'auto' | 'always' | 'never';
  scaleDistribution?: {
    type: 'linear' | 'log' | 'percent' | 'distribution';
    log?: number;
  };
  scaleYRange?: { min: number; max: number };
};

export type ChartRenderDataProps = {
  data: Array<number[]>;
  series: Series[];
  maxValue?: number;
  minValue?: number;
};

export type TimeseriesRenderProps = {
  bands?: Band[];
  chartTypes?: ChartType[];
  chartKey?: string;
  className?: string;
  cursorState?: CursorStateProps;
  date?: DateSelection;
  defaultActiveChart?: ChartType;
  chartData: ChartRenderDataProps;
  hooks?: [{ type: string; hook: (u: uPlot, si: number) => void }];
  kfuseHook?: UplotExtended['kfuseHook'];
  isLoading?: boolean;
  layoutType?: LayoutType;
  plugins?: Plugin[];
  onCursorStateChange?: (cursorState: CursorStateProps) => void;
  onSeriesShowHide?: (index: number, series: Series[]) => void;
  renderComponent?: (props: TimeseriesToolProps) => ReactElement;
  renderLegend?: (props: TimeseriesToolProps) => ReactElement;
  renderTooltip?: (props: TimeseriesToolProps) => ReactElement;
  renderToolbar?: (props: TimeseriesToolProps) => ReactElement;
  renderAnnotation?: (props: TimeseriesToolProps) => ReactElement;
  onChartTypeChange?: (val: ChartType) => void;
  seriesLimit?: number;
  size: { width: number; height?: number };
  styles?: UplotChartStyles;
  strokeType?: StrokeType;
  unit: string;
  error?: Error;
};

export interface KfuseHooksProps {
  u: UplotExtended;
  si?: number;
}

export interface KfuseHooksAnnotationProps extends KfuseHooksProps {
  e: MouseEvent;
  close: () => void;
}

export interface UplotExtended extends uPlot {
  chartType: ChartType;
  clearCanvasByContext?: (ctx: CanvasRenderingContext2D) => void;
  cursorState: CursorStateProps;
  darkModeEnabled: boolean;
  date: DateSelection;
  eventOverlayCtx: CanvasRenderingContext2D;
  getRawData: () => number[][];
  getRawDataBySeriesIdx: (seriesIndex: number, pointIndex: number) => number;
  getTooltipData: (val: {
    bbox: DOMRect;
    seriesIdx: number;
    u: UplotExtended;
  }) => TooltipDataReturnProps;
  layoutType: LayoutType;
  outlierSpan: Array<{ start: number; end: number }>;
  renderCount?: number;
  scaleDistribution?: UplotChartStyles['scaleDistribution'];
  tooltipCtx?: CanvasRenderingContext2D;
  tooltipData?: TooltipCoordsProps;
  utcTimeEnabled: boolean;
  kfuseHook?: {
    kfuseTooltip?: ((props: KfuseHooksProps) => TooltipCoordsProps | void)[];
    kfuseSeriesFocus?: ((props: KfuseHooksProps) => ReactElement | void)[];
    kfuseAnnotation?: ((
      props: KfuseHooksAnnotationProps,
    ) => ReactElement | void)[];
  };
  eventsData?: {
    outlierSpan: {
      data: Map<number, { span: OutlierSpanProps; isFocused: boolean }>;
      axis: 'x' | 'y' | 'xy';
    };
  };
}

export type RUMViewEvent = {
  time: number;
  data: RumViewEventData;
};

export type RUMActionEvent = {
  time: number;
  data: RumActionEventData;
};

export type RUMEvent = RUMViewEvent | RUMActionEvent;

export type RumViewEventData = {
  'browser.name': string;
  date: string;
  'device.type': string;
  env: string;
  'geo.city': string;
  'geo.country': string;
  'os.name': string;
  service: string;
  'session.type': string;
  'usr.email': string;
  'usr.id': string;
  'usr.name': string;
  version: string;
  'view.cumulative_layout_shift': string;
  'view.first_input_delay': string;
  'view.id': string;
  'view.interaction_to_next_paint': string;
  'view.largest_contentful_paint': string;
  'view.loading_time': string;
  'view.loading_type': string;
  'view.time_spent': string;
  'view.url_path': string;
};

export type RumActionEventData = {
  'action.id': string;
  'action.type': string;
  'action.loading_time': string;
  'action.frustration.type': string;
  'application.id': string;
  date: string;
  service: string;
  'device.type': string;
  'device.model': string;
  'display.viewport.width': string;
  'display.viewport.height': string;
  'view.name': string;
  'view.id': string;
};

export type RUMQueryServiceResponse = {
  events: RUMEvent[];
  cursor: string;
};

export enum RumTab {
  list = 'list',
  timeseries = 'timeseries',
  performanceMonitoring = 'performance-monitoring',
}
export enum RumPerformanceSubtab {
  overview = 'overview',
  performance = 'performance',
  errors = 'errors',
  deployments = 'deployments',
  api = 'api',
}

export type RumAggregateResult = {
  data: {
    aggregateTable: {
      dimensions: {
        [key: string]: string;
      };
      aggregates: number[];
    }[];
  };
};

export enum RumEventType {
  VIEW = 'VIEW',
  ACTION = 'ACTION',
}

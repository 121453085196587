import {
  ChartLegendTableColumn,
  DateSelection,
  SelectedFacetValuesByName,
} from 'types';
import { Filter, useSearch } from 'hooks';
import React, { useMemo } from 'react';
import classnames from 'classnames';
import { ProductTour } from 'components';
import useRumPageState from 'hooks/pageStates/useRumPageState';
import { ChartGridV2 } from 'components';
import multipleQueryRangeWithLabelsForRUM from 'utils/chartGrid/multipleQueryRangeWithLabelsForRUM';
import { formatLatencyYAxisTick } from 'utils';

type GetRowArgs = {
  setDate: (date: DateSelection) => void;
  selectedFacetValuesByName: SelectedFacetValuesByName;
  filters: Filter[];
  search: ReturnType<typeof useSearch>;
};

export const getRows = ({
  setDate,
  selectedFacetValuesByName,
  filters,
  search,
}: GetRowArgs) => {
  const onSelection = (newDate) => {
    if (typeof newDate === 'object') {
      setDate(newDate);
    } else {
      const { startTimeUnix, endTimeUnix } = newDate;
      setDate({ startTimeUnix, endTimeUnix });
    }
  };

  const rumQueries = (aggregateField: string, aggregateFunction: string) => ({
    isActive: true,
    type: 'query',
    queryKey: 'query_a',
    query: {
      ...search.state,
      searchBarState: {
        filters,
      },
      aggregateField,
      aggregateFunction,
    },
  });

  return [
    [
      {
        charts: [
          {
            key: 'p99Latency',
            colorMap: {
              p99: '#1f77b4',
              p95: '#0e9f6e',
              p90: '#000000',
              p75: '#003f5c',
              p50: '#c3e29c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P99',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile99'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile99',
                  groupByLimit: 10,
                  selectedFacetValuesByName,
                }),
              ],
              [['p99']],
            ),
            unit: 'ns',
          },
          {
            key: 'p95Latency',
            colorMap: {
              p99: '#1f77b4',
              p95: '#0e9f6e',
              p90: '#000000',
              p75: '#003f5c',
              p50: '#c3e29c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P95',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile95'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile95',
                  groupByLimit: 10,
                  selectedFacetValuesByName,
                }),
              ],
              [['p95']],
            ),
            unit: 'ns',
          },
          {
            key: 'p90Latency',
            colorMap: {
              p99: '#1f77b4',
              p95: '#0e9f6e',
              p90: '#000000',
              p75: '#003f5c',
              p50: '#c3e29c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P90',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile90'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile90',
                  groupByLimit: 10,
                  selectedFacetValuesByName,
                }),
              ],
              [['p90']],
            ),
            unit: 'ns',
          },
          {
            key: 'p75Latency',
            colorMap: {
              p99: '#1f77b4',
              p95: '#0e9f6e',
              p90: '#000000',
              p75: '#003f5c',
              p50: '#c3e29c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P75',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile75'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile75',
                  groupByLimit: 10,
                  selectedFacetValuesByName,
                }),
              ],
              [['p75']],
            ),
            unit: 'ns',
          },
          {
            key: 'p50Latency',
            colorMap: {
              p99: '#1f77b4',
              p95: '#0e9f6e',
              p90: '#000000',
              p75: '#003f5c',
              p50: '#c3e29c',
            },
            yAxisTickFormatter: formatLatencyYAxisTick,
            label: 'Largest Contentful Paint P50',
            legendTableColumns: [
              ChartLegendTableColumn.key,
              ChartLegendTableColumn.min,
              ChartLegendTableColumn.max,
              ChartLegendTableColumn.avg,
            ],
            libraryType: 'uplot',
            onSelection,
            rumQueries: [
              rumQueries('view.largest_contentful_paint', 'percentile50'),
            ],
            query: multipleQueryRangeWithLabelsForRUM(
              [
                () => ({
                  aggregateField: 'view.largest_contentful_paint',
                  aggregateFunction: 'percentile50',
                  groupByLimit: 10,
                  selectedFacetValuesByName,
                }),
              ],
              [['p50']],
            ),
            unit: 'ns',
          },
        ],
      },
    ],
  ];
};

type Props = {
  rumPageState: ReturnType<typeof useRumPageState>;
};
const RumLargestContentfulPaint = ({ rumPageState }: Props) => {
  const { rumState } = rumPageState;
  const { selectedFacetValuesByNameState, dateState, filtersState } = rumState;
  const selectedFacetValuesByName = selectedFacetValuesByNameState.state;
  const [date, setDate] = dateState;
  const search = useSearch();
  const rows = useMemo(
    () =>
      getRows({
        setDate,
        selectedFacetValuesByName,
        filters: filtersState.state,
        search,
      }),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [date, selectedFacetValuesByName],
  );

  return (
    <div
      className={classnames({
        rum: true,
      })}
    >
      <div className="rum__main overflow-auto">
        <div>
          <ChartGridV2.ChartGrid date={date} rows={rows} />
        </div>
      </div>
      <ProductTour />
    </div>
  );
};

export default RumLargestContentfulPaint;

import { useState } from 'react';
import { getLogMetricsTimeSeriesLogQLV2 } from 'requests';
import {
  DataFrame,
  getLogQLWithMetaToLoad,
  getUsedQueriesLabelForFormula,
  filterLogqlFormulaMatchingLabels,
  logsDataTransformer,
  sortAndLimitTimeseries,
} from 'utils';
import {
  DashboardPanelType,
  DateSelection,
  LogsMetricForumlaProps,
  LogsMetricQueryProps,
  LogQLWithMetaProps,
  QueryDataProps,
} from 'types';

const useLogsMetricsQueryData = ({
  chartWidth,
  customerFilter,
  date,
  dataFormat,
  isRange,
}: {
  chartWidth?: number;
  customerFilter?: { key: string; value: string };
  date: DateSelection;
  dataFormat?: DashboardPanelType;
  isRange: boolean;
}) => {
  const [logsChartData, setLogsChartData] = useState<QueryDataProps>({});
  const rangeKey = isRange ? 'range' : 'instant';

  const handleLoading = (
    logQLWithMeta: LogQLWithMetaProps[],
    isLoading: boolean,
  ) => {
    setLogsChartData((prev) => {
      const newState = { ...prev };
      logQLWithMeta.forEach(({ queryType, meta }) => {
        const queryId = `${queryType}_${meta.refId}`;
        newState[queryId] = { [rangeKey]: null, isLoading, meta };
      });
      return newState;
    });
  };

  const handleError = (logQLWithMeta: LogQLWithMetaProps, error: string) => {
    setLogsChartData((prev) => {
      const newState = { ...prev };
      const { queryType, meta } = logQLWithMeta;
      const queryId = `${queryType}_${meta.refId}`;
      newState[queryId] = { [rangeKey]: null, isLoading: false, meta, error };
      return newState;
    });
  };

  const loadMultipleLogsChartData = async ({
    formulas,
    formulaOnly = false,
    queries,
    chartWidth,
  }: {
    formulas: LogsMetricForumlaProps[];
    formulaOnly?: boolean;
    queries: LogsMetricQueryProps[];
    chartWidth: number;
  }) => {
    const logQLWithMeta = getLogQLWithMetaToLoad({
      customerFilter,
      dataFormat,
      date,
      formulas,
      formulaOnly,
      queries,
      instant: !isRange,
    });

    try {
      handleLoading(logQLWithMeta, true);
      const tempLogsChartData = { ...logsChartData };
      const tempLabelKeys: { [key: string]: string[] } = {};

      for (let idx = 0; idx < logQLWithMeta.length; idx++) {
        const logQL = logQLWithMeta[idx];
        const transformer = logsDataTransformer(false);
        if (logQL.limit) {
          transformer.splice(1, 0, {
            id: 'sortAndLimitTimeseries',
            func: (dataFrame: DataFrame) =>
              sortAndLimitTimeseries(dataFrame, logQL.limit),
          });
        }

        if (logQL.queryType === 'formula') {
          const labelBitmap = getUsedQueriesLabelForFormula({
            formulaExpression: logQL.meta.formulaExpression,
            tempLogsChartData,
            tempLabelKeys,
          });
          if (labelBitmap) {
            transformer.splice(2, 0, {
              id: 'filterLogqlFormulaMatchingLabels',
              func: (dataFrame: DataFrame) =>
                filterLogqlFormulaMatchingLabels({
                  dataFrame,
                  labelBitmap,
                }),
            });
          }
        }

        const data = await getLogMetricsTimeSeriesLogQLV2({
          date,
          logQL: logQL.logql,
          meta: logQL.meta,
          transformer,
          width: chartWidth,
        }).catch((e) => handleError(logQL, e));

        if (!data) return;
        const { meta, queryType } = logQL;
        const queryId = `${queryType}_${meta.refId}`;
        tempLogsChartData[queryId] = {
          [rangeKey]: data,
          isLoading: false,
          meta,
        };
        tempLabelKeys[queryId] = logQL.meta.labels;

        setLogsChartData((prev) => {
          const newState = { ...prev };
          newState[queryId] = { [rangeKey]: data, isLoading: false, meta };
          return newState;
        });
      }
    } catch (e) {}
  };

  const loadInstantMultipleLogsChartData = async ({
    formulas,
    formulaOnly,
    queries,
    chartWidth,
  }: {
    formulas: LogsMetricForumlaProps[];
    formulaOnly?: boolean;
    queries: LogsMetricQueryProps[];
    chartWidth: number;
  }) => {
    const logQLWithMeta = getLogQLWithMetaToLoad({
      chartWidth,
      customerFilter,
      dataFormat,
      date,
      formulas,
      formulaOnly,
      queries,
      instant: true,
    });

    handleLoading(logQLWithMeta, true);
    const tempLogsChartData = { ...logsChartData };
    const tempLabelKeys: { [key: string]: string[] } = {};

    for (let idx = 0; idx < logQLWithMeta.length; idx++) {
      const logQL = logQLWithMeta[idx];
      const transformer = logsDataTransformer(true);
      if (logQL.limit) {
        transformer.splice(2, 0, {
          id: 'sortAndLimitTimeseries',
          func: (dataFrame: DataFrame) =>
            sortAndLimitTimeseries(dataFrame, logQL.limit),
        });
      }

      if (logQL.queryType === 'formula') {
        const labelBitmap = getUsedQueriesLabelForFormula({
          formulaExpression: logQL.meta.formulaExpression,
          tempLogsChartData,
          tempLabelKeys,
        });

        if (labelBitmap) {
          transformer.push({
            id: 'filterLogqlFormulaMatchingLabels',
            func: (dataFrame: DataFrame) =>
              filterLogqlFormulaMatchingLabels({
                dataFrame,
                labelBitmap,
              }),
          });
        }
      }

      const dataset = await getLogMetricsTimeSeriesLogQLV2({
        date,
        instant: true,
        logQL: logQL.logql,
        meta: logQL.meta,
        transformer,
        width: chartWidth,
      }).catch((e) => handleError(logQL, e));

      if (!dataset) return;
      const { meta, queryType } = logQL;
      const queryId = `${queryType}_${meta.refId}`;
      tempLabelKeys[queryId] = logQL.meta.labels;
      tempLogsChartData[queryId] = { instant: dataset, isLoading: false, meta };

      setLogsChartData((prev) => {
        const newState = { ...prev };
        newState[queryId] = { instant: dataset, isLoading: false, meta };
        return newState;
      });
    }
  };

  const loadLogqlQuery = async (query: LogsMetricQueryProps) => {
    if (isRange) {
      loadMultipleLogsChartData({ formulas: [], queries: [query], chartWidth });
    } else {
      loadInstantMultipleLogsChartData({
        formulas: [],
        queries: [query],
        chartWidth,
      });
    }
  };

  return {
    loadMultipleLogsChartData,
    loadInstantMultipleLogsChartData,
    logsChartData,
    loadLogqlQuery,
    setLogsChartData,
  };
};

export default useLogsMetricsQueryData;

import { SearchState } from 'hooks/useSearch';
import { DashboardPanelType, DateSelection } from 'types';
import {
  DataFrameMeta,
  DataTransformerConfig,
  getRollupByVisualization,
  onPromiseError,
} from 'utils';
import rumTimeSeries from './rumTimeSeries';
import { getMappedGroupByLabels } from 'screens/Events/utils';
import rumDataTransformer from 'utils/DataTransformer/rumDataTransformer';

type Args = {
  dataFormat?: DashboardPanelType;
  date: DateSelection;
  instant?: boolean;
  queries: SearchState[];
  transformer: DataTransformerConfig[];
};

const fetchRumTimeseriesData = async ({ dataFormat, date, queries }: Args) => {
  const datasetsQueries = await Promise.all(
    queries.map(async (query) => {
      const searchBarState = query.searchBarState;
      const isInstant = dataFormat === 'timeseries';
      const baseTransformer = rumDataTransformer(!isInstant);

      if (!searchBarState) return undefined;

      const { groupBys, limitToValue, rollUpInSeconds } = query;
      const metricName =
        query.operation === 'distinctcount' ? 'count' : query.operation;

      const meta: DataFrameMeta = {
        aggregate: metricName,
        refId: query.queryKey,
        step: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        type: dataFormat,
        metricName: metricName,
        executedDate: date,
        labels: getMappedGroupByLabels(groupBys),
        unit: 'number',
      };
      const filters = query.searchBarState.filters.map((filter) => {
        const facet = filter.value.facet || filter.value.key;
        const values =
          Object.keys(filter.value.values)[0] || filter.value.values[0];
        return {
          [facet]: {
            [values]: 1,
          },
        };
      });
      const data = await rumTimeSeries({
        date,
        rollupSeconds: rollUpInSeconds || getRollupByVisualization(date, 'bar'),
        aggregateField: query.aggregateField,
        aggregateFunction: query.aggregateFunction,
        groupByLimit: limitToValue,
        transformer: baseTransformer as DataTransformerConfig[],
        meta,
        selectedFacetValuesByName: filters[0],
      }).catch(onPromiseError);
      return data;
    }),
  );
  const datasets = [...datasetsQueries];
  return datasets;
};
export default fetchRumTimeseriesData;
